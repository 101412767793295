<template>
    <div
        class="card card-overflow-visible counted-statistic pull-up"
        @click.prevent="navigate_or_emit(link)"
    >
        <div class="card-body">
            <div class="statistic-container d-flex">
                <div class="flex-grow">
                    <router-link
                        v-if="link"
                        :to="link"
                        v-slot="{ href, navigate }"
                        custom
                    >
                        <h3>
                            <base-link
                                :href="href"
                                :class="{ [`text-${color}`]: true }"
                                @click.native.prevent.stop="navigate_or_emit(href, navigate, $event)"
                            >
                                <span v-if="asDollars">$&nbsp;</span>{{ count | nibnut.number("0,0") }}<span v-if="asPercentage">&nbsp;%</span>
                                <span
                                    v-if="total"
                                    class="text-gray text-small"
                                >&nbsp;/&nbsp;<span v-if="asDollars">$&nbsp;</span>{{ total | nibnut.number("0,0") }}<span v-if="asPercentage">&nbsp;%</span></span>
                            </base-link>
                        </h3>
                        <h6>
                            <base-link
                                :href="href"
                                :class="{ [`text-${color}`]: true }"
                                @click.native.prevent.stop="navigate_or_emit(href, navigate, $event)"
                            >
                                {{ title }}
                            </base-link>
                            <slot name="controls"></slot>
                        </h6>
                    </router-link>
                    <div v-else>
                        <h3>
                            <span :class="{ [`text-${color}`]: true }"><span v-if="asDollars">$&nbsp;</span>{{ count | nibnut.number("0,0") }}<span v-if="asPercentage">&nbsp;%</span></span>
                            <span
                                v-if="total"
                                class="text-gray text-small"
                            >&nbsp;/&nbsp;<span v-if="asDollars">$&nbsp;</span>{{ total | nibnut.number("0,0") }}<span v-if="asPercentage">&nbsp;%</span></span>
                        </h3>
                        <h6>
                            {{ title }}
                            <slot name="controls"></slot>
                        </h6>
                    </div>
                </div>
                <div class="flex-static">
                    <slot name="glyph">
                        <open-icon
                            :glyph="glyph"
                            size="4x"
                            :class="{ [`text-${color}`]: true }"
                        />
                    </slot>
                </div>
            </div>
            <slot v-if="!!total">
                <progress
                    :value="count"
                    :max="total || count"
                    :class="{ [`progress-${color}`]: true }"
                    class="progress"
                ></progress>
            </slot>
        </div>
    </div>
</template>

<script>
import BaseLink from "@/nibnut/components/Links/BaseLink"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "CountedStatistic",
    components: {
        BaseLink,
        OpenIcon
    },
    methods: {
        navigate_or_emit (href, navigate, $event) {
            if(!href || !this.link) return
            if(!href || href.match(/#/i)) this.$emit("click", { href, navigate, event: $event })
            else navigate($event)
        }
    },
    computed: {
        count_label () {
            if(this.countLabel) return this.countLabel
            return this.nibnut_filter("nibnut.number", [this.count, "0,0"])
        }
    },
    props: {
        title: {
            type: String,
            required: true
        },
        count: {
            type: Number,
            required: true
        },
        total: {
            type: Number,
            default: 0
        },
        link: {
            default () {
                return null
            }
        },
        glyph: {
            type: String,
            default: ""
        },
        color: {
            type: String,
            validator: prop => !!prop && prop.match(/^(primary|secondary|tertiary|dark|gray|light|success|warning|error)$/),
            default: "primary"
        },
        asDollars: {
            type: Boolean,
            default: false
        },
        asPercentage: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.counted-statistic {
    .card-body {
        .statistic-container {
            .btn {
                height: auto;
                line-height: 1em;
                padding-top: 0;
                padding-bottom: 0;
            }
            img {
                display: block;
                max-height: 64px;
            }
        }
        progress {
            box-shadow: 0 10px 18px 0 rgb(62 57 107 / 20%);
        }
    }
}
</style>
