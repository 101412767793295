<template>
    <form-tag-input
        v-bind="form_tag_input_props"
        name="listing_ids"
        label-field="computed_display_title"
        :placeholder="translate('Street Address or MLS #')"
        data-source="listing"
        :show-all="false"
        :required="false"
        v-on="$listeners"
    >
        <template v-slot:read_only><slot name="read_only"></slot></template>
        <template v-slot:label><slot name="label"></slot></template>
        <template v-slot:hint><slot name="hint"></slot></template>
        <template v-slot:selection="{ selected_options }">
            <div
                v-for="option in selected_options"
                :key="option.id"
                class="chip"
            >
                {{ option.computed_display_title }}
                <a
                    href
                    class="btn btn-clear"
                    aria-label="Close"
                    role="button"
                    @click.prevent="unpick(option)"
                ></a>
            </div>
        </template>
        <template v-slot:option="{ option }">
            <div class="columns">
                <div class="column col-1">
                    <div
                        class="nibnut-aspect-ratio-box nibnut-ratio-gallery"
                    >
                        <div>
                            <img
                                v-if="option.computed_poster_url"
                                :src="option.computed_poster_url"
                                :alt="option.computed_display_title"
                                loading="lazy"
                                decoding="async"
                                class="img-responsive"
                            />
                            <open-icon
                                v-else
                                glyph="camera"
                                size="2x"
                            />
                        </div>
                    </div>
                </div>
                <div class="column">
                    {{ option.computed_display_title }}
                    <div class="text-small text-gray">{{ translate("MLS #{number}", { number: option.mls_id }) }}</div>
                </div>
            </div>
        </template>
    </form-tag-input>
</template>

<script>
import FormTagInput from "@/nibnut/components/Inputs/FormTagInput"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "FormListingLinkInput",
    components: {
        FormTagInput,
        OpenIcon
    },
    methods: {
        unpick (option) {
            const selection = [...this.value]
            const index = selection.indexOf(option.id)
            if(index >= 0) selection.splice(index, 1)
            this.$emit("input", selection, "listing_ids", option)
        }
    },
    computed: {
        form_tag_input_props () {
            return {
                id: this.id,
                value: this.value,
                editable: this.editable,
                saving: this.saving,
                error: this.error
            }
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        value: {
            default: null
        },
        editable: {
            type: Boolean,
            default: true
        },
        saving: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ""
        }
    }
}
</script>
