var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card card-overflow-visible counted-statistic pull-up",on:{"click":function($event){$event.preventDefault();return _vm.navigate_or_emit(_vm.link)}}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"statistic-container d-flex"},[_c('div',{staticClass:"flex-grow"},[(_vm.link)?_c('router-link',{attrs:{"to":_vm.link,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var _obj, _obj$1;

var href = ref.href;
var navigate = ref.navigate;return [_c('h3',[_c('base-link',{class:( _obj = {}, _obj[("text-" + _vm.color)] = true, _obj ),attrs:{"href":href},nativeOn:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.navigate_or_emit(href, navigate, $event)}}},[(_vm.asDollars)?_c('span',[_vm._v("$ ")]):_vm._e(),_vm._v(_vm._s(_vm._f("nibnut.number")(_vm.count,"0,0"))),(_vm.asPercentage)?_c('span',[_vm._v(" %")]):_vm._e(),(_vm.total)?_c('span',{staticClass:"text-gray text-small"},[_vm._v(" / "),(_vm.asDollars)?_c('span',[_vm._v("$ ")]):_vm._e(),_vm._v(_vm._s(_vm._f("nibnut.number")(_vm.total,"0,0"))),(_vm.asPercentage)?_c('span',[_vm._v(" %")]):_vm._e()]):_vm._e()])],1),_c('h6',[_c('base-link',{class:( _obj$1 = {}, _obj$1[("text-" + _vm.color)] = true, _obj$1 ),attrs:{"href":href},nativeOn:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.navigate_or_emit(href, navigate, $event)}}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_vm._t("controls")],2)]}}],null,true)}):_c('div',[_c('h3',[_c('span',{class:( _obj = {}, _obj[("text-" + _vm.color)] = true, _obj )},[(_vm.asDollars)?_c('span',[_vm._v("$ ")]):_vm._e(),_vm._v(_vm._s(_vm._f("nibnut.number")(_vm.count,"0,0"))),(_vm.asPercentage)?_c('span',[_vm._v(" %")]):_vm._e()]),(_vm.total)?_c('span',{staticClass:"text-gray text-small"},[_vm._v(" / "),(_vm.asDollars)?_c('span',[_vm._v("$ ")]):_vm._e(),_vm._v(_vm._s(_vm._f("nibnut.number")(_vm.total,"0,0"))),(_vm.asPercentage)?_c('span',[_vm._v(" %")]):_vm._e()]):_vm._e()]),_c('h6',[_vm._v(" "+_vm._s(_vm.title)+" "),_vm._t("controls")],2)])],1),_c('div',{staticClass:"flex-static"},[_vm._t("glyph",function(){
var _obj;
return [_c('open-icon',{class:( _obj = {}, _obj[("text-" + _vm.color)] = true, _obj ),attrs:{"glyph":_vm.glyph,"size":"4x"}})]})],2)]),(!!_vm.total)?_vm._t("default",function(){
var _obj;
return [_c('progress',{staticClass:"progress",class:( _obj = {}, _obj[("progress-" + _vm.color)] = true, _obj ),attrs:{"max":_vm.total || _vm.count},domProps:{"value":_vm.count}})]}):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }