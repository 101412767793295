<template>
    <div>
        <div class="agenda">
            <div class="agenda-head">
                <div
                    v-for="(date, index) in dates"
                    :key="index"
                    :class="{ 'clickable': editable && date.isSameOrAfter($dayjs(), 'day') }"
                    @click.prevent="schedule_showing(date)"
                >
                    <default-button
                        v-if="can_go_back(date)"
                        style="link"
                        size="sm"
                        @click.prevent.stop="go_back()"
                    >
                        <open-icon glyph="caret-left" />
                    </default-button>
                    <div>
                        <span v-if="showings_for_date(date).length">{{ date | nibnut.date("dd") }}: {{ showings_for_date(date).length }}</span>
                        <span v-else>{{ date | nibnut.date("ddd") }}</span>
                    </div>
                    <div class="text-small">
                        {{ date | nibnut.date("MM/DD") }}
                    </div>
                    <default-button
                        v-if="can_go_forward(date)"
                        style="link"
                        size="sm"
                        @click.prevent.stop="go_forward()"
                    >
                        <open-icon glyph="caret-right" />
                    </default-button>
                </div>
            </div>
            <div class="agenda-body">
                <div v-if="loading" class="text-center p-4">
                    <loader />
                </div>
                <div v-else-if="!showings.length" class="text-gray text-center p-4">
                    {{ translate("No Showings This Week") }}
                </div>
                <div
                    v-else
                    v-for="(date, index) in dates"
                    :key="index"
                    :class="{ 'clickable': editable && date.isSameOrAfter($dayjs(), 'day') }"
                    @click.prevent.stop="schedule_showing(date)"
                >
                    <div
                        v-for="showing in showings_for_date(date)"
                        :key="showing.id"
                        :class="{ clickable: (editable && !is_past_showing(showing)) || (listingId !== showing.listing_id) }"
                        class="card card-agenda pull-up"
                        @click.prevent.stop="edit_or_navigate(showing)"
                    >
                        <div class="card-header">
                            <div class="card-title h6">
                                <default-button
                                    v-if="editable && !is_past_showing(showing)"
                                    flavor="link"
                                    :title="translate('Edit Showing')"
                                    class="btn-edit-showing"
                                    @click.prevent.stop="edit_showing(showing)"
                                >
                                    {{ showing.starts_at | nibnut.date("MMM, DD") }} @ {{ showing.starts_at | nibnut.date("HH:mm") }}
                                    <open-icon glyph="pen" class="ml-1" />
                                </default-button>
                                <router-link
                                    v-else-if="listingId !== showing.listing_id"
                                    :to="{ name: 'listing.edit', params: {id: showing.listing_id } }"
                                    v-slot="{ href, navigate }"
                                    custom
                                >
                                    <base-link
                                        :id="`edit-listing-${showing.listing_id}-by-date`"
                                        :href="href"
                                        class="text-dark"
                                        @click.stop="navigate"
                                    >
                                        {{ showing.starts_at | nibnut.date("MMM, DD") }} @ {{ showing.starts_at | nibnut.date("HH:mm") }}
                                    </base-link>
                                </router-link>
                                <span v-else>
                                    {{ showing.starts_at | nibnut.date("MMM, DD") }} @ {{ showing.starts_at | nibnut.date("HH:mm") }}
                                </span>
                            </div>
                            <div class="card-subtitle">
                                <router-link
                                    v-if="listingId !== showing.listing_id"
                                    :to="{ name: 'listing.edit', params: {id: showing.listing_id } }"
                                    v-slot="{ href, navigate }"
                                    custom
                                >
                                    <base-link
                                        :id="`edit-listing-${showing.listing_id}`"
                                        :href="href"
                                        class="text-dark"
                                        @click.stop="navigate"
                                    >
                                        {{ showing.display_title }}
                                    </base-link>
                                </router-link>
                                <span v-else>
                                    {{ showing.display_title }}
                                </span>
                            </div>
                        </div>
                        <div class="card-body contact-info text-small text-gray">
                            <div v-if="!!showing_realtor_name(showing).realtor">
                                {{ showing_realtor_name(showing).realtor }}
                            </div>
                            <div v-else-if="showing.from">
                                {{ showing.from }}
                            </div>
                            <div v-else>
                                {{ translate("Unknown Realtor") }}
                            </div>
                            <div v-if="!!email_for_showing(showing).phone || !!email_for_showing(showing).reply_url" class="contact-buttons">
                                <mailto-link
                                    v-if="email_for_showing(showing).phone"
                                    :value="email_for_showing(showing).phone"
                                    filter="telto"
                                    class="mr-2"
                                    @click.native.stop
                                />
                                <base-link
                                    v-if="email_for_showing(showing).reply_url"
                                    :href="email_for_showing(showing).reply_url"
                                    :title="translate('Reply')"
                                    target="_blank"
                                    @click.native.stop
                                >
                                    <open-icon v-if="email_for_showing(showing).reply_url.match(/^mailto/i)" glyph="envelope" />
                                    <img v-else src="@/assets/img/touchbase-logo.png" />
                                </base-link>
                            </div>
                            <div v-if="!!showing_realtor_name(showing).company">{{ showing_realtor_name(showing).company }}</div>
                        </div>
                        <div
                            :class="{ 'text-success': !!showing.confirmed_at, 'text-error': !showing.confirmed_at, 'text-bold': !showing.confirmed_at }"
                            class="card-footer text-small text-center text-uppercase"
                        >
                            <span v-if="!!showing.confirmed_at">{{ translate("Confirmed") }}</span>
                            <span v-else>{{ translate("Unconfirmed") }}</span>
                            <default-button
                                v-if="editable"
                                flavor="link"
                                color="error"
                                size="sm"
                                :title="translate('Delete Showing')"
                                class="btn-delete-showing float-right"
                                @click.prevent.stop="confirm_delete(showing)"
                            >
                                <open-icon glyph="times-circle" />
                            </default-button>
                            <div
                                v-if="showing.is_modification"
                                class="bg-warning text-center text-small"
                            >
                                {{ translate("Modification") }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <default-button
            v-if="editable"
            :block="true"
            class="mt-4"
            @click.prevent.stop="schedule_showing($dayjs())"
        >
            {{ translate("Schedule a new showing...") }}
        </default-button>

        <showing-editor-dialog
            v-if="editing"
            :show.sync="editing"
            :record="edited_showing"
            :feedback="feedback_for_showing(edited_showing)"
            @create="create_showing"
        />
        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </div>
</template>

<script>
import { is_remote_data_table_source, handles_dates, crud_utilities, confirms } from "@/nibnut/mixins"
import addl_profile_utilities from "@/custom/mixins/AddlProfileUtilities"

import { MailtoLink, BaseLink, DefaultButton, OpenIcon } from "@/nibnut/components"
import ShowingEditorDialog from "@/custom/dialogs/ShowingEditorDialog"
import Loader from "@/custom/components/Loader"

const default_length = 30

export default {
    name: "Agenda",
    mixins: [is_remote_data_table_source, handles_dates, crud_utilities, confirms, addl_profile_utilities],
    components: {
        MailtoLink,
        BaseLink,
        DefaultButton,
        OpenIcon,
        ShowingEditorDialog,
        Loader
    },
    watch: {
        listingId: "refresh",
        "state.from": "refresh",
        days: "recenter"
    },
    methods: {
        pre_load () {
            if(!this.state.from) {
                const today = this.$dayjs().startOf("day")
                this.set_state_values({
                    from: today.format("YYYY-MM-DD"),
                    to: today.add(this.days - 1, "days").endOf("day").format("YYYY-MM-DD")
                })
            }
            this.set_state_value("listing_id", this.listingId)
        },
        recenter () {
            this.set_date_range({
                from: this.$dayjs(this.state.from),
                to: this.$dayjs(this.state.from).add(this.days - 1, "days")
            })
            this.refresh()
        },
        refresh_totals () {
            const showings_today = this.showings_today.length
            this.$emit("loaded", { showings_today })
        },
        feedback_for_showing (showing) {
            return this.entity_records("listing_feedback").find(feedback => feedback.listing_showing_id === showing.id)
        },
        email_for_showing (showing) {
            let email = this.entity_records("listing_email").find(email => {
                return (email.topic_id === showing.id) && (email.topic_type === "App\\ListingShowing")
            })
            if(!email) email = { from: showing.from, reply_url: showing.reply_url }
            return email
        },
        showing_realtor_name (showing) {
            const email = this.email_for_showing(showing)
            return {
                realtor: email ? email.from_name : null,
                company: email ? email.from_company : null
            }
        },
        is_past_showing (showing) {
            return !!showing && this.is_past_date(showing.starts_at)
        },
        edit_or_navigate (showing) {
            if(this.listingId === showing.listing_id) this.edit_showing(showing)
            else this.navigate(showing.listing_id)
        },
        navigate (listing_id) {
            const node = document.getElementById(`edit-listing-${listing_id}`)
            if(node) node.click()
        },
        can_go_back (date) {
            return this.$dayjs(this.state.from).isSame(date, "day")
        },
        go_back () {
            const days = this.days
            this.set_date_range({
                from: this.$dayjs(this.state.from).subtract(days, "days"),
                to: this.$dayjs(this.state.to).subtract(days, "days")
            })
        },
        can_go_forward (date) {
            return this.$dayjs(this.state.to).isSame(date, "day")
        },
        go_forward () {
            const days = this.days
            this.set_date_range({
                from: this.$dayjs(this.state.from).add(days, "days"),
                to: this.$dayjs(this.state.to).add(days, "days")
            })
        },
        set_date_range (range) {
            this.set_state_values({
                from: this.$dayjs(range.from).format("YYYY-MM-DD"),
                to: this.$dayjs(range.to).format("YYYY-MM-DD")
            })
        },
        edit_showing (showing, force = false) {
            if(!showing || !this.editable) return
            if(this.is_past_showing(showing) || force) this.navigate(showing.listing_id)
            else {
                this.edited_showing = showing
                this.editing = true
            }
        },
        schedule_showing (date) {
            const now = this.$dayjs()
            if(!this.editable || date.isBefore(now, "day")) return
            this.$store.dispatch(
                "FETCH_RECORD_SHELL",
                { entity: this.entity }
            ).then(showing => {
                showing.listing_id = this.listingId
                const showings = this.showings_for_date(date)
                showing.starts_at = this.$dayjs(date).hour(9).minute(0).second(0)
                if(showings.length) showing.starts_at = this.$dayjs(showings[showings.length - 1].ends_at).startOf("minute").second(0)
                if(this.is_past_showing(showing)) showing.starts_at = now.add(15 - (now.minute() % 15), "minute").startOf("minute").second(0)
                showing.ends_at = showing.starts_at.add(default_length, "minute")
                showing.starts_at = showing.starts_at.format("YYYY-MM-DD HH:mm:ss")
                showing.ends_at = showing.ends_at.format("YYYY-MM-DD HH:mm:ss")
                this.edit_showing(showing)
            }).catch(error => {
                this.$error(error.message)
            })
        },
        create_showing () {
            if(!this.editable) return
            this.$store.dispatch("CREATE_RECORD", {
                entity: this.entity,
                data: { ...this.edited_showing }
            }).then(record => {
                if(record) {
                    this.refresh()
                    this.edited_showing = {}
                    this.editing = false
                }
            }).catch(error => {
                this.$error(error.message)
            })
        },
        confirm_delete (showing) {
            this.edited_showing = showing
            let name = this.translate("Unknown Realtor")
            const email = this.email_for_showing(showing)
            if(email.from) name = email.from
            else if(showing.from) name = showing.from
            this.confirm(
                {
                    type: "error",
                    title: this.translate("Delete Showing"),
                    message: this.translate("Do you really want to delete the showing on {date} @ {time}, by {name}? There is no undo... NOTE: This will NOT send an email to the agent!", { date: this.nibnut_filter("nibnut.date", [this.edited_showing.starts_at]), time: this.nibnut_filter("nibnut.date", [this.edited_showing.starts_at, "HH:mm"]), name }),
                    cancel: this.translate("Keep"),
                    ok: this.translate("Delete")
                },
                "delete-room"
            )
        },
        confirmed () {
            this.done_confirming()
            this.$store.dispatch(
                "RECORD_DELETE",
                {
                    entity: this.entity,
                    id: this.edited_showing.id
                }
            ).catch(this.receive_error)
        },
        showings_for_date (date) {
            if(!date) return []
            const from = date.startOf("day")
            const to = date.endOf("day")
            return this.showings.filter(showing => {
                const showing_date = this.$dayjs(showing.starts_at)
                return showing_date.isSameOrAfter(from) && showing_date.isSameOrBefore(to)
            })
        }
    },
    computed: {
        state_identifier () {
            return "agenda"
        },
        can_refresh () {
            return !!this.profile_id
        },
        fields () {
            return ["fieldset::list", "ns::listing_email;fieldset::showings-list", "ns::listing;fieldset::showings-list", "ns::listing_feedback;fieldset::feedback"]
        },
        editable () {
            return this.is_at_least_realtor
        },
        showings () {
            return this.entity_records(this.entity, this.current_records_ids)
        },
        showings_today () {
            return this.showings.filter(showing => this.$dayjs(showing.starts_at).isToday())
        },
        past_showings () {
            return this.showings.filter(showing => this.is_past_showing(showing))
        },
        days () {
            if(this.numberOfDays) return this.numberOfDays
            if(this.$mq === "xl") return 5
            if(this.$mq === "lg") return 4
            if(this.$mq === "md") return 3
            if(this.$mq.match(/^(xs|sm)$/i)) return 2
            return 7
        },
        dates () {
            const from = this.$dayjs(this.state.from)
            return (new Array(this.days)).fill(0).map((noop, index) => {
                return from.add(index, "day")
            })
        }
    },
    props: {
        listingId: {
            type: Number,
            default: 0
        },
        numberOfDays: {
            type: Number,
            default: 0
        }
    },
    data () {
        return {
            entity: "listing_showing",
            relation_ids: ["listing_email", "listing"],
            default_state: {
                per_page: 0,
                page: 1,
                sort_by: "starts_at",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0,
                listing_id: 0,
                context: "agenda",
                from: null,
                to: null
            },
            editing: false,
            edited_showing: {}
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.agenda {
    & > .agenda-head,
    & > .agenda-body {
        display: flex;

        & > div {
            flex: 1 1 300px;
        }
    }
    & > .agenda-head {
        background-color: $primary-color;
        color: $light-color;

        & > div {
            position: relative;
            text-align: center;
            border-left: 1px solid $light-color;

            &.clickable {
                cursor: copy;
            }

            .btn {
                position: absolute;
                top: 0;
                height: 100%;

                &:first-child {
                    left: 0;
                }
                &:last-child {
                    right: 0;
                }
            }
        }
    }
        & > .agenda-body {
        & > div {
            padding: 0 $layout-spacing;
            height: 275px;
            overflow: auto;
            background-color: lighten($gray-color, 27%);
            border-left: 1px solid $light-color;

            &.clickable {
                cursor: copy;
            }

            .card.card-agenda {
                margin-top: $layout-spacing;
                box-shadow: 0 2px $card-box-shadow-width-thin 1px rgb(49 53 72 / 10%);

                .btn.btn-edit-showing {
                    padding-left: 0;
                    padding-right: 0;
                    text-align: left;
                    color: $body-font-color;
                }
                .btn.btn-delete-showing {
                    padding: 0;
                    float: right;
                    height: auto;
                    line-height: normal;
                }
            }
        }
    }
}
</style>
