<template>
    <form-group
        v-bind="form_group_props"
        class="form-date-range-input"
    >
        <template v-slot:read_only>
            <slot name="read_only">
                <label
                    v-if="has_label"
                    class="form-label"
                    :for="identifier"
                >
                    <slot name="label"></slot>
                </label>
                <label
                    class="form-label disabled-field"
                >
                    {{ display_value }}
                </label>
            </slot>
        </template>
        <template v-slot:label><slot name="label"></slot></template>
        <template v-slot:hint><slot name="hint"></slot></template>

        <div
            :class="{ active: picking }"
            class="popover popover-bottom col-12"
            @mouseenter="start_picking"
            @mouseleave="stop_picking"
        >
            <default-button
                :block="block"
                :size="size"
                :waiting="saving"
            >
                {{ display_value }}
            </default-button>
            <div class="popover-container">
                <div class="card">
                    <div class="card-body">
                        <base-calendar
                            :selection="value"
                            :min="min"
                            :max="max"
                            :clickable="true"
                            :marks="marks"
                            @click="pick"
                        />
                        <label
                            v-if="allowSingleDayRange"
                            class="form-input-hint"
                        >
                            {{ translate("Hold the Shift key to define a range") }}
                        </label>
                        <div
                            v-if="!!presets && !!presets.length"
                            class="btn-group btn-group-block mt-2"
                        >
                            <default-button
                                v-for="preset in presets"
                                :key="preset.title"
                                flavor="link"
                                size="sm"
                                @click.prevent="$emit('input', preset.range)"
                            >
                                {{ preset.title }}
                            </default-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form-group>
</template>

<script>
import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"
import handles_dates from "@/nibnut/mixins/HandlesDates"

import FormGroup from "@/nibnut/components/Inputs/FormGroup"
import BaseCalendar from "@/nibnut/components/Inputs/BaseCalendar"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"

export default {
    name: "FormDateRangeInput",
    mixins: [is_nibnut_component, handles_dates],
    components: {
        FormGroup,
        BaseCalendar,
        DefaultButton
    },
    methods: {
        start_picking () {
            this.last_pick = ""
            this.picking = true
        },
        stop_picking () {
            this.last_pick = ""
            this.picking = false
        },
        pick (date, event) {
            const range = { ...this.value }
            range.from = this.$dayjs(range.from)
            range.to = this.$dayjs(range.to)

            const pick_range = () => {
                if(!range.from || date.isSameOrBefore(range.from, "day")) {
                    this.last_pick = "from"
                    range.from = date
                } else if(!range.to || date.isSameOrAfter(range.to, "day")) {
                    this.last_pick = "to"
                    range.to = date
                } else if(this.last_pick === "from") {
                    this.last_pick = "to"
                    range.to = date
                } else {
                    this.last_pick = "from"
                    range.from = date
                }
            }
            if(this.allowSingleDayRange) {
                if(!!event && !!event.shiftKey) pick_range()
                else {
                    this.last_pick = "from"
                    range.from = date
                    range.to = date
                }
            } else pick_range()

            range.from = range.from.format("YYYY-MM-DD")
            range.to = range.to.format("YYYY-MM-DD")
            this.$emit("input", range)
        }
    },
    computed: {
        has_label () {
            return this.has_slot("label")
        },
        form_group_props () {
            return {
                id: this.id,
                name: this.name,
                value: this.value,
                required: this.required,
                editable: this.editable,
                error: this.error,
                waiting: this.saving
            }
        },
        display_value () {
            return this.standardized_date_range_label(this.value, {}, this.emptyLabel)
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        value: { // {from: <date>, to: <date>}
            type: Object,
            required: true
        },
        min: { // null, string or native date
            default: null
        },
        max: { // null, string or native date
            default: null
        },
        size: {
            type: String,
            validator: prop => !!prop && prop.match(/^(sm|md|lg)$/i),
            default: "md"
        },
        emptyLabel: {
            type: String,
            default: "Any Period"
        },
        dateFormat: {
            type: String,
            default: "MM/DD/YYYY"
        },
        presets: {
            type: Array,
            default () {
                return [
                    { title: "1 Mo", range: { to: this.$dayjs(), from: this.$dayjs().subtract(30, "days") } },
                    { title: "1 Yr", range: { to: this.$dayjs(), from: this.$dayjs().subtract(1, "years") } },
                    { title: "2 Yrs", range: { to: this.$dayjs(), from: this.$dayjs().subtract(2, "years") } },
                    { title: "3 Yrs", range: { to: this.$dayjs(), from: this.$dayjs().subtract(3, "years") } },
                    { title: "4 Yrs", range: { to: this.$dayjs(), from: this.$dayjs().subtract(4, "years") } },
                    { title: "5 Yrs", range: { to: this.$dayjs(), from: this.$dayjs().subtract(5, "years") } }
                ]
            }
        },
        marks: {
            type: [Object, Array],
            default () {
                return [] // "{ entity: <entity>, data: {} }" (/<entity>/calendar-marks?from=&to=&<data>) or ["YYYY-MM-DD", "YYYY-MM-DD", ...]
            }
        },
        allowSingleDayRange: {
            type: Boolean,
            default: false
        },
        block: {
            type: Boolean,
            default: true
        },
        disabled: { // disable input field
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            required: true
        },
        editable: { // read-only
            type: Boolean,
            default: true
        },
        saving: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ""
        }
    },
    data () {
        return {
            picking: false,
            last_pick: ""
        }
    }
}
</script>

<style lang="scss">
.form-date-range-input {
    .popover-container {
        width: auto;
    }
}
</style>
