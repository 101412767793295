<template>
    <div v-if="!!statistics" class="columns chance-of-selling-graphs">
        <div
            :class="{ 'col-12': !graph_ids.length, 'col-6': !!graph_ids.length && (graph_ids.length <= 2), 'col-4': (graph_ids.length === 4) }"
            class="column col-sm-12 text-center"
        >
            <div class="h1">
                {{ (listing.current_chance_of_selling || statistics.chance_of_selling) | nibnut.number("0") }}%
            </div>
            <slot></slot>
        </div>
        <div
            :class="{ 'col-12': !graph_ids.length, 'col-6': !!graph_ids.length && (graph_ids.length <= 2), 'col-8': (graph_ids.length === 4) }"
            class="column col-sm-12 columns"
        >
            <div
                v-for="graph_id in graph_ids"
                :key="graph_id"
                :class="{ 'col-6': (graph_ids.length === 4) }"
                class="column col-sm-12 mb-1 chance-of-selling-graph"
                @click.prevent="toggle_zoom(chart_title(graph_id), graph_id)"
            >
                <Portal to="destination-modal-destination" :disabled="zoomed !== graph_id">
                    <apexchart
                        :height="(zoomed === graph_id) ? 400 : 68"
                        :options="chart_options(graph_id)"
                        :series="chart_data(graph_id)"
                    />
                </Portal>
                <form-toggle-input
                    v-if="editable"
                    :id="`seller-view-${graph_id}`"
                    name="chance_of_selling_graph_ids"
                    type="checkbox"
                    size="sm"
                    :value="!!listing.chance_of_selling_graph_ids && (listing.chance_of_selling_graph_ids.indexOf(graph_id) >= 0)"
                    :required="false"
                    @click.native.stop
                    @input="toggle_graph_id(graph_id)"
                >
                    {{ translate("Show Seller") }}
                </form-toggle-input>
            </div>
        </div>
        <destination-modal
            v-if="zoomed"
            :show.sync="zoomed"
            :title="zoom_title"
        />
    </div>
</template>

<script>
import profile_utilities from "@/nibnut/mixins/ProfileUtilities"
import displays_chart from "@/custom/mixins/DisplaysChart"
import uses_destination_modal from "@/custom/mixins/UsesDestinationModal"

import FormToggleInput from "@/nibnut/components/Inputs/FormToggleInput"

export default {
    name: "ChanceOfSellingGraphs",
    mixins: [profile_utilities, displays_chart, uses_destination_modal],
    components: {
        FormToggleInput
    },
    mounted () {
        this.refresh()
    },
    watch: {
        profile_id: "refresh",
        "listing.id": "refresh",
        advancedQuery: "refresh",
        dataVersion: "refresh"
    },
    methods: {
        refresh () {
            if(!this.profile_id || !this.listing || !this.listing.id) return
            this.$store.dispatch(
                "RECORD_ACTION",
                {
                    entity: "listing",
                    id: this.listing.id,
                    action: "chance-of-selling",
                    data: {
                        advanced_query: this.advancedQuery
                    },
                    passthru: true
                }
            ).then(statistics => {
                this.statistics = statistics
            }).catch(error => {
                this.$error(error.message)
            })
        },
        chart_title (graph_id) {
            let title
            switch (graph_id) {
            case "activity":
                title = this.translate("Market")
                break
            case "dom":
                title = this.translate("Days On Market (Avg)")
                break
            case "price":
                title = this.translate("Price/Sale")
                break
            case "price-changes":
                title = this.translate("Price Changes")
                break
            }
            return title
        },
        price_ratio_data (serie_A, serie_B) {
            if(!serie_A) serie_A = Object.values(this.statistics.data.price.active)
            if(!serie_B) serie_B = Object.values(this.statistics.data.price.sales)
            return serie_A.map((price, index) => {
                if(!price) return 0
                return (serie_B[index] / price) * 100
            })
        },
        chart_options (graph_id) {
            const title = this.chart_title(graph_id)

            const serie_ids = Object.keys(this.statistics.data[graph_id])
            const options = {
                ...this.CHART_BASE_OPTIONS,
                height: (this.zoomed === graph_id) ? 400 : 68,
                labels: Object.keys(this.statistics.data[graph_id][serie_ids[0]]).map(key => key.replace(/^.+::/, "")),
                yaxis: {
                    show: (this.zoomed === graph_id)
                }
            }
            options.chart = {
                ...options.chart,
                id: graph_id,
                type: (graph_id === "price") ? "bar" : "line",
                sparkline: {
                    enabled: (this.zoomed !== graph_id)
                }
            }
            options.legend = {
                show: (this.zoomed === graph_id)
            }
            options.title = {
                text: (this.zoomed === graph_id) ? undefined : title
            }
            options.grid = {
                xaxis: {
                    show: (this.zoomed === graph_id),
                    lines: {
                        show: false
                    }
                }
            }
            options.tooltip = {
                ...options.tooltip,
                y: [
                    {
                        formatter: (value) => {
                            return this.nibnut_filter("nibnut.number", [value, "0,0"])
                        }
                    },
                    {
                        formatter: (value) => {
                            return this.nibnut_filter("nibnut.number", [value, "0,0"])
                        }
                    }
                ]
            }
            options.yaxis.labels = {
                formatter: value => {
                    return `${this.nibnut_filter("nibnut.number", [value, "0,0"])}`
                }
            }
            if(graph_id === "price-changes") {
                const serie = Object.keys(this.statistics.data[graph_id].active)[0]
                options.labels = Object.keys(this.statistics.data[graph_id].active[serie]).map(key => key.replace(/^.+::/, ""))
            } else if(graph_id === "activity") {
                options.yaxis = [
                    {
                        ...options.yaxis,
                        seriesName: this.translate("Sold Listings"),
                        showAlways: true
                    },
                    {
                        ...options.yaxis,
                        seriesName: this.translate("Sold Listings"),
                        show: false
                    },
                    {
                        ...options.yaxis,
                        seriesName: this.translate("Sold Listings"),
                        show: false
                    },
                    {
                        ...options.yaxis,
                        opposite: true,
                        title: {
                            text: this.translate("Months of Inventory")
                        },
                        labels: {
                            formatter: value => {
                                return `${this.nibnut_filter("nibnut.number", [value, "0,0"])} months`
                            }
                        }
                    }
                ]
                options.tooltip.y.push({
                    formatter: (value) => {
                        return this.nibnut_filter("nibnut.number", [value, "0,0"])
                    }
                })
                options.tooltip.y.push({
                    formatter: (value) => {
                        return `${this.nibnut_filter("nibnut.number", [value, "0,0"])} months`
                    }
                })
            } else if(graph_id === "price") {
                if(this.zoomed === graph_id) {
                    options.annotations = {
                        position: "back",
                        yaxis: [
                            {
                                y: 100,
                                yAxisIndex: 2,
                                borderColor: this.CHART_COLORS[2],
                                fillColor: this.CHART_COLORS[2],
                                label: {
                                    text: `${this.nibnut_filter("nibnut.number", [100, "0,0.0"])}%`
                                }
                            }
                        ]
                    }
                }

                options.xaxis = { tickAmount: 15 }

                options.yaxis.tickAmount = 4
                const line_min = Math.min(...Object.values(this.statistics.data[graph_id].active), ...Object.values(this.statistics.data[graph_id].sales))
                const line_max = Math.max(...Object.values(this.statistics.data[graph_id].active), ...Object.values(this.statistics.data[graph_id].sales))
                const price_ratio = this.price_ratio_data()
                let ratio_min = Math.min(...price_ratio)
                let ratio_max = Math.max(...price_ratio)
                if(ratio_max < 100) ratio_max = ratio_min + (2 * (100 - ratio_min))
                else if(ratio_min > 100) ratio_min = ratio_max - (2 * (ratio_max - 100))

                options.yaxis = [
                    {
                        ...options.yaxis,
                        showAlways: true,
                        min: line_min,
                        max: line_max
                    },
                    {
                        ...options.yaxis,
                        show: false,
                        min: line_min,
                        max: line_max
                    },
                    {
                        ...options.yaxis,
                        // min: ratio_min,
                        max: ratio_max,
                        opposite: true,
                        title: {
                            text: this.translate("Price/Sale")
                        },
                        labels: {
                            formatter: value => {
                                return `${this.nibnut_filter("nibnut.number", [value, "0,0.0"])}%`
                            }
                        }
                    }
                ]
                if(graph_id === "price") {
                    delete options.yaxis[0].min
                    delete options.yaxis[1].min
                }
                options.tooltip.y.push({
                    formatter: (value) => {
                        return `${this.nibnut_filter("nibnut.number", [value, "0,0.0"])}%`
                    }
                })
            }
            /* else if(graph_id === "price-changes") {
                options.yaxis.labels = {
                    formatter: value => {
                        return `${this.nibnut_filter("nibnut.number", [value, "0,0.0000"])}%`
                    }
                }
                options.tooltip.y[0].formatter = (value) => {
                    return `${this.nibnut_filter("nibnut.number", [value, "0,0.0000"])}%`
                }
                options.tooltip.y[1].formatter = (value) => {
                    return `${this.nibnut_filter("nibnut.number", [value, "0,0.0000"])}%`
                }
            }
            */

            return options
        },
        chart_data (graph_id) {
            let data = []

            if(graph_id === "price-changes") {
                const build_data = (series_id, title_prefix) => {
                    Object.keys(this.statistics.data[graph_id][series_id]).forEach(serie_id => {
                        data.push({
                            name: `${title_prefix} (${serie_id})`,
                            data: Object.values(this.statistics.data[graph_id][series_id][serie_id])
                        })
                    })
                }
                build_data("sales", this.translate("Sales"))
                build_data("active", this.translate("Price Changes"))
                return data
            }

            let serie_A = Object.values(this.statistics.data[graph_id].sales)
            let serie_B = this.statistics.data[graph_id].active ? Object.values(this.statistics.data[graph_id].active) : null

            let serie_A_title = this.translate("Sold Listings")
            let serie_B_title = this.translate("Active Listings")
            switch (graph_id) {
            case "price":
                serie_A_title = this.translate("Sold Price (Avg)")
                serie_B_title = this.translate("Asking Price (Avg)")
                // add 3rd line and opposite y-axis to chart ratio
                serie_A = serie_A.map(value => value || null)
                serie_B = serie_B.map(value => value || null)
                break
            }

            data = [
                {
                    name: serie_A_title,
                    data: serie_A
                }
            ]
            if(serie_B) {
                data.push({
                    name: serie_B_title,
                    data: serie_B
                })
            }

            if(graph_id === "activity") {
                data.push({
                    name: this.translate("Cancelled / Off Market"),
                    data: Object.values(this.statistics.data[graph_id].cancelled)
                })
                if(this.zoomed === graph_id) {
                    data.push({
                        name: this.translate("Inventory"),
                        data: Object.values(this.statistics.data[graph_id].inventory)
                    })
                }
            } else if(graph_id === "price") {
                data.push({
                    name: this.translate("Price/Sale"),
                    data: this.price_ratio_data(serie_B, serie_A).map(value => value || null)
                })
            }

            return data
        },
        toggle_graph_id (graph_id) {
            if(this.listing && this.editable) {
                const chance_of_selling_graph_ids = this.listing.chance_of_selling_graph_ids ? [...this.listing.chance_of_selling_graph_ids] : []
                const index = chance_of_selling_graph_ids.indexOf(graph_id)
                if(index >= 0) chance_of_selling_graph_ids.splice(index, 1)
                else chance_of_selling_graph_ids.push(graph_id)
                this.$emit("input", chance_of_selling_graph_ids.length ? chance_of_selling_graph_ids : null, "chance_of_selling_graph_ids")
            }
        }
    },
    computed: {
        graph_ids () {
            if(this.statistics) return Object.keys(this.statistics.data)
            return []
        }
    },
    props: {
        listing: {
            type: Object,
            required: true
        },
        advancedQuery: {
            default: null
        },
        editable: {
            type: Boolean,
            default: false
        },
        dataVersion: {
            type: Number,
            default: 0
        }
    },
    data () {
        return {
            statistics: null
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.chance-of-selling-graphs {
    .h1 {
        font-size: 4.7rem;
    }
    .chance-of-selling-graph {
        position: relative;
        & > .form-group {
            position: absolute;
            top: 0;
            right: 0;

            & > label.form-checkbox {
                margin: 0;
                margin-top: -0.1rem;
            }
        }
    }
}
</style>
