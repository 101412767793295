<template>
    <div>
        <div class="columns mb-4">
            <div class="column col-6 col-lg-12 col-mx-auto">
                <div class="btn-group btn-group-block">
                    <default-button
                        tabindex="0"
                        :active="!state.filter && !state.archived"
                        @click.prevent="filter(null, null)"
                    >
                        {{ translate("All") }}
                    </default-button>
                    <default-button
                        tabindex="0"
                        :active="state.filter_on === 'is_unpublished'"
                        :disabled="!counts.unpublished"
                        @click.prevent="filter('is_unpublished', true)"
                    >
                        <span v-if="counts.unpublished">{{ translate("{n} Unpublished", { n: counts.unpublished }) }}</span>
                        <span v-else>{{ translate("Unpublished") }}</span>
                    </default-button>
                    <default-button
                        tabindex="0"
                        :active="state.filter_on === 'is_pending'"
                        :disabled="!counts.pending"
                        @click.prevent="filter('is_pending', true)"
                    >
                        <span v-if="counts.pending">{{ translate("{n} Pending", { n: counts.pending }) }}</span>
                        <span v-else>{{ translate("Pending") }}</span>
                    </default-button>
                    <default-button
                        tabindex="0"
                        :active="state.filter_on === 'published_at'"
                        :disabled="!counts.published"
                        @click.prevent="filter('published_at', true)"
                    >
                        <span v-if="counts.published">{{ translate("{n} Published", { n: counts.published }) }}</span>
                        <span v-else>{{ translate("Published") }}</span>
                    </default-button>
                    <default-button
                        tabindex="0"
                        :active="state.archived"
                        @click.prevent="filter_archived"
                    >
                        {{ translate("No Feedback") }}
                    </default-button>
                </div>
            </div>
        </div>
        <data-table
            id="feedback-list"
            entity-name="feedback:::feedback"
            :columns="visible_columns"
            :rows="filtered_rows"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :can-add="false"
            :searchable="false"
            :show-head="false"
            :clickable="false"
            :empty-title="empty_title"
            @sort="sort_by"
            @page="goto_page"
        >
            <template
                v-slot:summary="{ row }"
            >
                <div class="columns">
                    <div class="column col-6">
                        <showings-list-feedback-cell
                            field="computed_display_title"
                            :feedback="row"
                            :showing="showing_for_row(row)"
                            :listing="listing_by_id(row.listing_id)"
                            :email="email_for_row(row)"
                        />
                    </div>
                    <div class="column col-6">
                        <showings-list-feedback-cell
                            field="feedback"
                            :feedback="row"
                            :showing="showing_for_row(row)"
                            :listing="listing_by_id(row.listing_id)"
                            :email="email_for_row(row)"
                            @changed-status="recount"
                        />
                        <showings-list-feedback-cell
                            field="received_feedback"
                            :feedback="row"
                            :showing="showing_for_row(row)"
                            :listing="listing_by_id(row.listing_id)"
                            :email="email_for_row(row)"
                            @changed-status="recount"
                        />
                    </div>
                </div>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <showings-list-feedback-cell
                    :field="field"
                    :feedback="row"
                    :showing="showing_for_row(row)"
                    :listing="listing_by_id(row.listing_id)"
                    :email="email_for_row(row)"
                    @changed-status="recount"
                />
            </template>
        </data-table>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import { is_remote_data_table_source } from "@/nibnut/mixins"

import { DefaultButton } from "@/nibnut/components"
import ShowingsListFeedbackCell from "./ShowingsListFeedbackCell"

export default {
    name: "FeedbackList",
    mixins: [is_remote_data_table_source],
    components: {
        DefaultButton,
        ShowingsListFeedbackCell
    },
    watch: {
        feedback: "refresh",
        listingId: "refresh",
        minDate: "refresh"
    },
    methods: {
        pre_load () {
            this.set_state_values({
                listing_id: this.listingId,
                from: this.minDate ? this.$dayjs(this.minDate).format("YYYY-MM-DD") : null
            })
        },
        post_load (response) {
            this.counts = { pending: response.meta.pending || 0, unpublished: response.meta.unpublished || 0, published: response.meta.published || 0 }
            this.$emit("loaded", this.counts)
        },
        filter (filter_on, filter) {
            this.filtered_by_selection = false
            this.set_state_values({
                page: 1,
                filter_on: filter_on || null,
                filter,
                archived: false
            })
            return this.refresh()
        },
        filter_archived () {
            this.set_state_values({
                page: 1,
                filter_on: null,
                archived: true
            })
            return this.refresh()
        },
        showing_for_row (feedback) {
            return this.entity_record("listing_showing", feedback.listing_showing_id) || {}
        },
        email_for_row (feedback) {
            let email = this.entity_records("listing_email").find(email => {
                return (email.topic_id === feedback.listing_showing_id) && (email.topic_type === "App\\ListingShowing")
            })
            if(!email) {
                const showing = this.showing_for_row(feedback)
                email = { from: showing.from, reply_url: showing.reply_url }
            }
            return email
        },
        listing_by_id (id) {
            return this.entity_record("listing", id) || {}
        },
        recount (feedback, old_values = null) {
            if(old_values) {
                if(old_values.is_unpublished && (feedback.is_unpublished !== old_values.is_unpublished)) {
                    this.counts.unpublished--
                    this.counts.published++
                } else if(old_values.is_pending && (feedback.is_pending !== old_values.is_pending)) this.counts.pending--
            }
            if(feedback.is_unpublished && (feedback.is_unpublished !== old_values.is_unpublished)) {
                this.counts.published--
                this.counts.unpublished++
            } else if(feedback.is_pending && (feedback.is_pending !== old_values.is_pending)) this.counts.pending++
        }
    },
    computed: {
        ...mapGetters(["entity_record"]),
        state_identifier () {
            return "feedback-list"
        },
        can_refresh () {
            return !!this.profile_id
        },
        fields () {
            return ["fieldset::feedback", "ns::listing_email;fieldset::feedback-list", "ns::listing;fieldset::feedback-list", "ns::listing_showing;fieldset::feedback-list"]
        },
        empty_title () {
            if(this.state.filter_on === "is_pending") return this.translate("No Pending Feedback")
            if(this.state.filter_on === "is_unpublished") return this.translate("No Unpublished Feedback")
            if(this.state.listing_id) return this.translate("No Feedback Available")
            return this.translate("No Pending or Unpublished Feedback")
        },
        pending () {
            return this.entity_records(this.entity).filter(feedback => {
                return feedback.is_pending
            })
        },
        unpublished () {
            return this.entity_records(this.entity).filter(feedback => {
                return feedback.is_unpublished
            })
        }
    },
    props: {
        listingId: {
            type: Number,
            default: 0
        },
        minDate: { // null, string or native date
            default: null
        }
    },
    data () {
        return {
            entity: "listing_feedback",
            relation_ids: ["listing_showing", "listing_email", "listing"],
            default_state: {
                per_page: 3,
                page: 1,
                sort_by: "showed_at",
                sort_dir: "desc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0,
                listing_id: 0,
                from: null,
                to: null
            },
            columns: {
                computed_display_title: { label: " ", sort: false },
                feedback: { label: " ", sort: false },
                received_feedback: { label: " ", sort: false }
            },
            counts: {
                pending: 0,
                unpublished: 0,
                published: 0
            }
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.nibnut-data-table#feedback-list {
    td {
        height: 0;
        vertical-align: top;
        @media(max-width:$size-sm) {
            height: auto;
        }

        &:first-child {
            width: 200px;
        }

        .btn.btn-block {
            text-align: left;
            padding-left: 0;
        }

        .cell-feedback {
            flex-direction: column;
            @media(max-width:$size-xl) {
                height: auto;
            }
        }
    }
    & > .data-table-content {
        padding: 0;

        .image-holder {
            position: relative;
            width: 20%;
            @media(max-width:$size-sm) {
                width: auto;
            }

            h6 {
                display: inline-block;
            }
        }
        .tooltip {
            position: relative;
        }
    }
}
</style>
