<template>
    <div
        :class="{ 'bootstrapping': !bootstrapped }"
        class="carousel-container"
    >
        <img :src="images[current_index].url" :alt="images[current_index].name" loading="lazy" decoding="async" class="img-responsive">
        <div
            v-if="images.length > 1"
            class="carousel"
        >
            <input
                v-for="(image, index) in navigation_images"
                :key="image.id"
                :id="slide_id_for_index(index)"
                type="radio"
                :name="`carousel-radio-${index}`"
                hidden=""
                :checked="index === current_index"
                class="carousel-locator"
            />
            <div class="carousel-container">
                <figure
                    v-for="(image, index) in images"
                    :key="image.id"
                    :class="{ active: (index === current_index), clickable: clickable }"
                    class="carousel-item"
                    @click.prevent.stop="maybe_click"
                >
                    <label
                        v-if="!disabled"
                        :for="slide_id_for_index(index - 1)"
                        class="item-prev btn btn-action btn-lg"
                        @click.prevent.stop="set_current_index(index - 1)"
                    >
                        <open-icon glyph="chevron-circle-left" size="2x" />
                    </label>
                    <label
                        v-if="!disabled"
                        :for="slide_id_for_index(index + 1)"
                        class="item-next btn btn-action btn-lg"
                        @click.prevent.stop="set_current_index(index + 1)"
                    >
                        <open-icon glyph="chevron-circle-right" size="2x" />
                    </label>
                    <img
                        :src="image.url"
                        :alt="image.name"
                        loading="lazy"
                        decoding="async"
                        class="img-responsive"
                    />
                </figure>
            </div>
            <div class="carousel-nav">
                <label
                    v-for="(image, index) in navigation_images"
                    :key="image.id"
                    :for="slide_id_for_index(index)"
                    :class="{ active: (index === current_index) }"
                    class="nav-item text-hide c-hand"
                    @click.prevent.stop="set_current_index(index)"
                >
                    {{ index }}
                </label>
            </div>
        </div>
    </div>
</template>

<script>
import OpenIcon from "@/nibnut/components/OpenIcon"

const standardized_index = (source, index) => {
    if(index < 0) index = source.length - 1
    else if(index >= source.length) index = 0
    return index
}

export default {
    name: "Carousel",
    components: {
        OpenIcon
    },
    watch: {
        images: "reset",
        startAt: "reset_index"
    },
    mounted () {
        this.reset()
    },
    methods: {
        reset () {
            this.reset_index()
            if(this.images.length > 1) {
                this.bootstrapped = false
                setTimeout(() => {
                    this.bootstrapped = true
                }, 800) // keep in sync with sliding animation's length
            }
        },
        reset_index () {
            this.set_current_index(this.startAt)
        },
        slide_id_for_index (index) {
            const image = this.images[standardized_index(this.images, index)]
            return `carousel-${this.id}-slide-${image.id}`
        },
        set_current_index (index) {
            this.current_index = standardized_index(this.images, index)
            if(this.track && window.gtag) {
                window.gtag("event", "image", {
                    event_category: "view",
                    event_label: this.images[this.current_index].uuid,
                    value: this.trackValue
                })
            }
        },
        maybe_click () {
            if(this.disabled) this.$emit("click")
            else if(this.clickable) this.$store.dispatch("LIGHTBOX", { images: this.images, start_at: this.current_index, value: this.trackValue })
        }
    },
    computed: {
        navigation_images () {
            if(this.disabled) return []
            return this.images
        }
    },
    props: {
        id: {
            required: true
        },
        images: {
            type: Array,
            required: true
        },
        clickable: {
            type: Boolean,
            default: true
        },
        startAt: {
            type: Number,
            default: 0
        },
        track: {
            type: Boolean,
            default: false
        },
        trackValue: {
            type: Number,
            default: 0
        },
        disabled: {
            type: Boolean,
            defualt: false
        }
    },
    data () {
        return {
            bootstrapped: false,
            current_index: 0
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.carousel-container {
    & > img {
        z-index: $zindex-0;
    }
    & > .carousel {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: $zindex-1;
    }
    &.bootstrapping {
        & > .carousel {
            visibility: hidden;
        }
    }
}
</style>
