<template>
    <div class="columns feedback-rating">
        <div v-if="!!label" class="column flex-static">
            {{ label }}:
        </div>
        <div class="column">
            <span
                v-for="index in max_stars"
                :key="index"
                :class="{ 'text-gray': (index > stars), 'text-primary': (index <= stars) }"
            >
                <open-icon glyph="star" />
            </span>
            <span v-if="!star_rating">{{ rating }}</span>
        </div>
    </div>
</template>

<script>
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "FeedbackRating",
    components: {
        OpenIcon
    },
    computed: {
        star_rating () {
            if(this.rating) return this.rating.match(/(\d+)\/(\d+)/)
            return null
        },
        max_stars () {
            const rating = this.star_rating
            return rating ? parseInt(rating[2]) : 0
        },
        stars () {
            const rating = this.star_rating
            return rating ? parseInt(rating[1]) : 0
        }
    },
    props: {
        label: {
            type: String,
            default: ""
        },
        rating: {
            type: String,
            required: true
        }
    }
}
</script>
