<template>
    <div
        :class="{ horizontal }"
        class="feedback-reviews-list"
    >
        <div class="card">
            <div class="card-header">
                <slot>
                    <h6>
                        <span v-if="state.found"> {{ translate("1 Listing feedback to review:::{n} Listing feedbacks to review", { n: state.found }, state.found) }}</span>
                        <span v-else> {{ translate("Feedback") }}</span>
                    </h6>
                </slot>
            </div>
            <div class="card-body">
                <data-table
                    id="my-feedback-to-review"
                    entity-name="feedback:::feedbacks"
                    :columns="columns"
                    :rows="filtered_rows"
                    :search="state.search"
                    :total="state.total"
                    :found="state.found"
                    :current-page="state.page"
                    :total-pages="nb_pages"
                    :can-add="is_at_least_realtor"
                    :clickable="is_at_least_realtor"
                    :show-totals="false"
                    :show-head="false"
                    :searchable="false"
                    :empty-title="translate('No listing feedback to review at the moment')"
                    @add="create"
                    @click="start_editing($event)"
                    @sort="sort_by"
                    @page="goto_page"
                >
                    <template
                        v-slot:tbody="{ row }"
                    >
                        <div>
                            <div class="d-flex align-items-center">
                                <div
                                    class="flex-static feedback-image-holder"
                                >
                                    <div
                                        class="nibnut-aspect-ratio-box nibnut-ratio-gallery"
                                    >
                                        <div>
                                            <img
                                                v-if="listing_for_row(row).computed_poster_url"
                                                :src="listing_for_row(row).computed_poster_url"
                                                :alt="listing_for_row(row).computed_display_title"
                                                loading="lazy"
                                                decoding="async"
                                                class="img-responsive"
                                            />
                                            <open-icon
                                                v-else
                                                glyph="camera"
                                                size="2x"
                                            />
                                        </div>
                                    </div>
                                    <open-icon
                                        v-if="!!row.published_at"
                                        glyph="check-circle"
                                        size="2x"
                                        class="text-success"
                                    />
                                </div>
                                <div class="flex-variable ml-4">
                                    <h6>
                                        {{ row.showed_at | nibnut.date("MMM, DD") }} @ {{ row.showed_at | nibnut.date("HH:mm") }}
                                    </h6>
                                    <h6 class="text-gray text-small">
                                        {{ listing_for_row(row).computed_display_title }}
                                    </h6>
                                </div>
                            </div>
                            <p v-if="!!row.comments">{{ row.comments }}</p>
                            <p v-else class="text-center text-gray"><em>({{ translate("No comment") }})</em></p>
                        </div>
                    </template>
                </data-table>
            </div>
        </div>

        <feedback-editor-dialog
            v-if="editing"
            :show.sync="editing"
            :record="edited_feedback"
            :listing="listing"
            @created="created"
        />
    </div>
</template>

<script type="text/javascript">
import { mapGetters } from "vuex"

import is_remote_data_table_source from "@/nibnut/mixins/IsRemoteDataTableSource"
import handles_saving from "@/nibnut/mixins/HandlesSaving"
import addl_profile_utilities from "@/custom/mixins/AddlProfileUtilities"

import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "FeedbackReviewsList",
    mixins: [is_remote_data_table_source, handles_saving, addl_profile_utilities],
    components: {
        OpenIcon,
        FeedbackEditorDialog: () => import("@/custom/dialogs/FeedbackEditorDialog")
    },
    watch: {
        listingId: "reset",
        listingShowingId: "reset"
    },
    methods: {
        pre_load () {
            this.set_state_values({
                per_page: this.perPage,
                filter: this.showAll ? "all" : "needs-review",
                listing_id: this.listingId,
                listing_showing_id: this.listingShowingId
            })
        },
        filter_rows (rows) {
            if(this.filtered_by_selection) {
                return rows.filter((row) => {
                    return this.selected_row_ids.indexOf(row.id) >= 0
                })
            }
            return rows
        },
        listing_for_row (row) {
            return this.entity_record("listing", row.listing_id) || {}
        },
        create () {
            this.$store.dispatch(
                "FETCH_RECORD_SHELL",
                { entity: "listing_feedback" }
            ).then(record => {
                record.listing_id = this.listingId
                record.listing_showing_id = this.listingShowingId
                this.start_editing(record)
            }).catch(error => {
                this.$error(error.message)
            })
        },
        start_editing (feedback) {
            this.edited_feedback = feedback
            this.editing = true
        },
        stop_editing () {
            if(!this.editing) {
                this.editing = false
                if(this.delete_fc) this.delete_fc()
                this.edited_feedback = null
            }
        },
        created (record) {
            this.current_records_ids.push(record.id)
        }
    },
    computed: {
        ...mapGetters(["entity_record"]),
        state_identifier () {
            return "feedback-reviews-list"
        },
        can_refresh () {
            return !!this.profile_id
        },
        fields () {
            return ["fieldset::feedback", "ns::listing;fieldset::feedback"]
        },
        listing () {
            if(!!this.edited_feedback && !!this.edited_feedback.listing_id) return this.entity_record("listing", this.edited_feedback.listing_id) || {}
            if(this.listingId) return this.entity_record("listing", this.listingId) || {}
            return this.entity_record("listing", this.showing.listing_id) || {}
        },
        showing () {
            return this.entity_record("showing", this.listingShowingId) || {}
        }
    },
    props: {
        listingId: {
            type: Number,
            default: 0
        },
        listingShowingId: {
            type: Number,
            default: 0
        },
        perPage: {
            type: Number,
            default: 3
        },
        showAll: {
            type: Boolean,
            default: true
        },
        horizontal: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            entity: "listing_feedback",
            relation_ids: ["listing"],
            apply_local_filter: false,
            columns: {
                on: { label: " ", sort: "asc", type: "numeric" }
            },
            default_state: {
                per_page: 3,
                page: 1,
                sort_by: "showed_at",
                sort_dir: "asc",
                filter_on: "review_status",
                filter: "all",
                archived: false,
                search: "",
                total: 0,
                found: 0,
                listing_id: 0,
                listing_showing_id: 0
            },
            edited_feedback: null,
            editing: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.feedback-reviews-list {
    .card {
        min-height: 100%;
        .card-body {
            padding-top: 0;
            .nibnut-data-table {
                & > .data-table-content {
                    padding: 0;

                    td > div {
                        height: 97px;
                        overflow: auto;
                    }
                    .feedback-image-holder {
                        position: relative;
                        width: 16.66666667%;

                        h6 {
                            display: inline-block;
                        }

                        & > i.las {
                            position: absolute;
                            background-color: $light-color;
                            border-radius: 50%;
                            right: 0;
                            bottom: 0;
                        }
                    }
                }
            }
        }
    }
    &.horizontal {
        @media (min-width: $size-lg) {
            .card {
                .card-body {
                    .nibnut-data-table {
                        & > .data-table-content {
                            td > div {
                                display: flex;
                                align-items: center;
                                & > div {
                                    flex: 0 0 auto;
                                    padding-right: $layout-spacing;
                                }
                                & > p {
                                    flex: 1 0 auto;
                                    padding-left: $layout-spacing;
                                }
                            }
                            .feedback-image-holder {
                                width: 10vw;
                                max-width: 170px;
                                min-width: 120px;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
