<template>
    <div class="events-calendar">
        <vue-cal
            ref="vuecal"
            :events="events"
            :editable-events="editableEvents"
            :show-all-day-events="showAllDayEvents"
            :activeView="activeView"
            :disable-views="disableViews"
            :drag-to-create-event="false"
            :time-from="start_hours * 60"
            :time-to="end_hours * 60"
            :time-cell-height="timeCellHeight"
            :snap-to-time="15"
            :onEventClick="edit"
            :onEventCreate="create"
            @ready="view_changed"
            @view-change="view_changed"
            @cell-click="$emit('cell-click', $event, $refs.vuecal)"
            @event-change="$emit('event-change', $event, $refs.vuecal)"
            @event-delete="$emit('event-delete', $event, $refs.vuecal)"
        />
        <label v-if="(editableEvents === true) || (!!editableEvents && !!editableEvents.delete)" class="form-input-hint">{{ translate("NOTE: Click and hold an event to delete it.") }}</label>
    </div>
</template>

<script type="text/javascript">
import VueCal from "vue-cal"
import "vue-cal/dist/drag-and-drop.js"
import "vue-cal/dist/vuecal.css"

export default {
    name: "EventsCalendar",
    components: {
        VueCal
    },
    created () {
        this.start_hours = 9
        this.end_hours = 20
    },
    methods: {
        post_load () {
            const calendar = document.querySelector(".vuecal__bg")
            if(calendar) {
                const now = this.$dayjs()
                const hours = Math.max(this.end_hours, Math.min(this.start_hours, now.hour() + (now.minute() / 60)))
                calendar.scrollTo({ top: (hours - this.start_hours) * this.timeCellHeight, behavior: "smooth" })
            }
        },
        view_changed ({ startDate, endDate }) {
            this.$emit("view-changed", {
                from: this.$dayjs(startDate).format("YYYY-MM-DD"),
                to: this.$dayjs(endDate).format("YYYY-MM-DD")
            })
        },
        edit (event, dom_event) {
            this.$emit("edit", event, dom_event)
        },
        date_is_past (date) {
            return date.isSameOrBefore(this.$dayjs(), "minute")
        },
        create (event, delete_fc) {
            this.$emit("create", event, delete_fc)
        }
    },
    props: {
        events: {
            type: Array,
            default () {
                return []
            }
        },
        editableEvents: {
            default: false
        },
        showAllDayEvents: {
            default: true
        },
        activeView: {
            type: String,
            validator: prop => !!prop && !!prop.match(/^(years?|month|week|day)$/i),
            default: "week"
        },
        disableViews: {
            type: Array,
            default () {
                return ["years", "year"]
            }
        },
        timeCellHeight: {
            type: Number,
            default: 80
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.events-calendar{
    .vuecal__no-event {
        display: none;
    }
    .vuecal__event {
        .vuecal__event-content {
            .btn.btn-link {
                position: absolute;
                right: 2px;
                bottom: 2px;
                height: auto;
                padding: 0;
                line-height: normal;
                z-index: 10;

                &.rated {
                    right: 24px;
                    opacity: 1;
                }
            }
        }
    }
    .vuecal__now-line {
        display: none;
    }
}
</style>
