<template>
    <graphed-statistic-card
        :id="id"
        :title="title"
        :count="count"
        :last-count="lastCount"
        :graph-data="graphData"
        :zoomed="zoomed"
        :glyph="glyph"
        :color="color"
        :legendColor="legendColor"
        :as-percentage="asPercentage"
        :inverted="inverted"
        :tooltip-footer-formatter="tooltip_footer_formatter"
        @toggle-zoom="toggle_zoom"
    >
        <template v-slot:icon>
            <slot></slot>
        </template>
        <template v-slot:subtitle>
            <slot name="subtitle">
                <span
                    :class="{ 'd-invisible': (lastCount < 0) }"
                >
                    {{ translate("Last year") }}: &nbsp;{{ lastCount | nibnut.number("0,0") }}<span v-if="asPercentage">&nbsp;%</span>
                </span>
            </slot>
        </template>
    </graphed-statistic-card>
</template>

<script>
import GraphedStatisticCard from "@/custom/components/GraphedStatisticCard"

export default {
    name: "ComparedStatistic",
    components: {
        GraphedStatisticCard
    },
    methods: {
        toggle_zoom (title, id) {
            if(!this.graphData) return
            this.$emit("toggle-zoom", title, id)
        },
        tooltip_footer_formatter ({ series, seriesIndex, dataPointIndex, w }) {
            if(!this.zoomed || !Array.isArray(this.graphData)) return ""

            const percentage = series[0][dataPointIndex] ? ((series[0][dataPointIndex] - series[1][dataPointIndex]) / series[0][dataPointIndex]) * 100 : 0
            let percentage_as_string = this.translate("unchanged")
            if(percentage && (Math.abs(percentage) < 1)) percentage_as_string = `${this.nibnut_filter("nibnut.number", [percentage, "0.0,00"])}&nbsp;%`
            else if(percentage) percentage_as_string = `${this.nibnut_filter("nibnut.number", [percentage, "0,0"])}&nbsp;%`
            let css_class = "text-gray"
            let arrow = ""
            if(percentage > 0) {
                css_class = "text-success"
                arrow = "arrow-up"
            } else if(percentage < 0) {
                css_class = "text-error"
                arrow = "arrow-down"
            }
            if(arrow) arrow = `<i class="las ${arrow}"></i>`

            return `
                <span class="${css_class} text-bold">
                    ${this.translate("Change")}:&nbsp;
                    ${arrow}
                    ${percentage_as_string}
                </span>
            `
        }
    },
    props: {
        id: {
            type: String,
            required: true
        },
        title: {
            type: [String, Array],
            required: true
        },
        count: {
            type: Number,
            required: true
        },
        lastCount: {
            type: Number,
            default: 0
        },
        inverted: {
            type: Boolean,
            default: false
        },
        graphData: {
            type: [Object, Array],
            default: null
        },
        zoomed: {
            default: false
        },
        glyph: {
            type: String,
            default: ""
        },
        color: {
            type: [String, Array],
            validator: prop => !!prop && (Array.isArray(prop) || prop.match(/^(primary|secondary|tertiary|dark|gray|light|success|warning|error)$/)),
            default: "primary"
        },
        legendColor: {
            type: [String, Array],
            validator: prop => !!prop && (Array.isArray(prop) || prop.match(/^(primary|secondary|tertiary|dark|gray|light|success|warning|error)$/))
        },
        asPercentage: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.compared-statistic {
    .card-body {
        .statistic-container {
            & > div + div {
                margin-left: $unit-2;
            }
            img {
                display: block;
                max-height: 64px;
            }
        }
    }
    .card-footer {
        &, &:last-child {
            padding: 0;
        }
    }
}
</style>
