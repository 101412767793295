<template>
    <div>
        <drop-list
            v-if="mode === 'sort'"
            :items="sorted_images"
            class="columns gallery"
            @reorder="image_sort"
        >
            <template v-slot:item="{ item }">
                <drag
                    class="column col-3 col-md-4 col-sm-12 gallery-item"
                    :key="item.id"
                >
                    <div
                        :class="{ [ratio]: true }"
                        class="nibnut-aspect-ratio-box"
                    >
                        <div>
                            <video
                                v-if="!image_is_local(item) && !!item.mime_type && !!item.mime_type.match(/video/i)"
                                autoplay
                                loop
                                muted
                                :src="item.url"
                                width="100%"
                            ></video>
                            <img
                                v-else
                                :src="item.url"
                                loading="lazy"
                                decoding="async"
                            />
                        </div>
                    </div>
                </drag>
            </template>
        </drop-list>
        <div v-else-if="mode === 'view'" class="gallery gallery-preview">
            <div
                v-for="(image, index) in sorted_posters"
                :key="image.id"
                :class="{ 'gallery-hero': !index }"
                class="gallery-item"
            >
                <video
                    v-if="!image_is_local(image) && !!image.mime_type && !!image.mime_type.match(/video/i)"
                    autoplay
                    loop
                    muted
                    :src="image.url"
                    width="100%"
                ></video>
                <img
                    v-else
                    :src="image.url"
                    :alt="image.name"
                    loading="lazy"
                    decoding="async"
                    class="img-responsive clickable"
                    @click.prevent="$store.dispatch('LIGHTBOX', { images: sorted_images, start_at: index })"
                />
            </div>
            <default-button
                v-if="images.length > 5"
                flavor="link"
                @click.prevent="$store.dispatch('LIGHTBOX', { images: sorted_images, start_at: images.length })"
            >
                {{ translate("View All") }}
                <open-icon glyph="long-arrow-alt-right" />
            </default-button>
        </div>
        <div v-else class="columns gallery">
            <div
                v-for="(image, index) in sorted_images"
                :key="image.id"
                class="column col-3 col-md-4 col-sm-12 gallery-item"
            >
                <div
                    :class="{ [ratio]: true }"
                    class="nibnut-aspect-ratio-box"
                >
                    <div>
                        <video
                            v-if="!image_is_local(image) && !!image.mime_type && !!image.mime_type.match(/video/i)"
                            autoplay
                            loop
                            muted
                            :src="image.url"
                            width="100%"
                        ></video>
                        <img
                            v-else-if="!image_is_local(image) && (type !== 'TYPE_BANNER') && !features"
                            :src="image.url"
                            :alt="image.name"
                            loading="lazy"
                            decoding="async"
                            class="img-responsive clickable"
                            @click.prevent="$store.dispatch('LIGHTBOX', { images: sorted_images, start_at: index })"
                        />
                        <image-upload-input
                            v-else
                            :url="image.url"
                            :name="image_post_name(image)"
                            :alt="image.name"
                            :replace-title="translate('Replace file')"
                            :accepts="upload_accepts(image_post_name(image))"
                            :uploading="!!uploading[image_post_name(image)]"
                            :uploaded="uploaded[image_post_name(image)] || 0"
                            :deletable="image_is_local(image) || (type === 'TYPE_BANNER') || features"
                            :clearing="clearing_file"
                            :replaceable="image_is_local(image)"
                            :disabled="!image_is_local(image) && (type !== 'TYPE_BANNER') && !features"
                            @upload="upload_file_list(image_post_name(image), $event, 'image_url')"
                            @clear="clear_or_confirm_clear_file(image)"
                        >
                            <template v-slot:dragging-title>
                                {{ translate("Drop your file to upload it") }}
                            </template>
                            <template v-slot:empty-title>
                                {{ (type === "TYPE_BANNER") ? translate("Drag your image or video here, or use the button below") : translate("Drag your image here, or use the button below") }}
                            </template>
                            <template v-slot:empty-button-title>
                                {{ (type === "TYPE_BANNER") ? translate("Select image or video...") : translate("Select image...") }}
                            </template>
                            <template v-slot:display>
                                <video
                                    v-if="!!image.mime_type && !!image.mime_type.match(/video/i)"
                                    autoplay
                                    loop
                                    muted
                                    :src="image.url"
                                    width="100%"
                                ></video>
                                <div
                                    v-else-if="image.url === 'TOOBIG'"
                                    class="empty"
                                >
                                    <div>
                                        <open-icon glyph="image" size="4x" />
                                        <p class="text-small empty-subtitle">{{ translate("Image too big for preview. It will be displayed once save.") }}</p>
                                    </div>
                                </div>
                                <img
                                    v-else
                                    :src="image.url"
                                    :alt="image.name"
                                />
                            </template>
                        </image-upload-input>
                    </div>
                </div>
                <div
                    v-if="copiable || image_is_featurable(image) || image_is_featured(image)"
                    class="gallery-item-floating-buttons"
                >
                    <default-button
                        v-if="copiable"
                        size="sm"
                        :title="copiable"
                        class="btn-copy mr-1"
                        @click.prevent.stop="$emit('copy', image)"
                    >
                        <open-icon glyph="tv" />
                    </default-button>
                    <default-button
                        v-if="image_is_featurable(image) || image_is_featured(image)"
                        flavor="link"
                        size="sm"
                        :title="image_is_featured(image) ? translate('This image is used as the parallax background') : translate('Use this image for the parallax background')"
                        class="btn-feature"
                        @click.prevent.stop="save(image, !image.is_featured, 'is_featured')"
                    >
                        <open-icon
                            :glyph="image_is_featured(image) ? 'star' : 'asterisk'"
                            :class="{ 'text-success': image_is_featured(image) }"
                        />
                    </default-button>
                </div>
                <form-input
                    v-if="(mode !== 'view') && image_is_local(image)"
                    id="name"
                    name="name"
                    v-model="image.name"
                    :required="true"
                    size="sm"
                    :placeholder="translate('Image name')"
                    @input="save(image, ...arguments)"
                />
            </div>
            <div
                v-if="(mode === 'edit') && !features"
                class="column col-3 col-md-4 col-sm-12 gallery-item"
            >
                <div
                    :class="{ [ratio]: true }"
                    class="nibnut-aspect-ratio-box"
                >
                    <div>
                        <image-upload-input
                            url=""
                            :name="image_post_name()"
                            :alt="(type === 'TYPE_BANNER') ? translate('Upload new image or video') : translate('Upload new image')"
                            :replace-title="translate('Replace file')"
                            :accepts="upload_accepts(image_post_name())"
                            :uploading="!!uploading[image_post_name()]"
                            :uploaded="uploaded[image_post_name()] || 0"
                            :deletable="false"
                            glyph="plus"
                            @upload="upload_file_list(image_post_name(), $event)"
                        >
                            <template v-slot:dragging-title>
                                {{ translate("Drop your file to upload it") }}
                            </template>
                            <template v-slot:empty-title>
                                {{ (type === "TYPE_BANNER") ? translate("Drag your image or video here, or use the button below") : translate("Drag your image here, or use the button below") }}
                            </template>
                            <template v-slot:empty-button-title>
                                {{ (type === "TYPE_BANNER") ? translate("Select image or video...") : translate("Select image...") }}
                            </template>
                        </image-upload-input>
                    </div>
                </div>
            </div>
        </div>
        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </div>
</template>

<script>
import orderBy from "lodash/orderBy"

import handles_saving from "@/nibnut/mixins/HandlesSaving"
import handles_uploads from "@/nibnut/mixins/HandlesUploads"

import { Drag, DropList } from "vue-easy-dnd"
import FormInput from "@/nibnut/components/Inputs/FormInput"
import ImageUploadInput from "@/nibnut/components/Inputs/ImageUploadInput"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

let new_id = -1

export default {
    name: "Gallery",
    mixins: [handles_saving, handles_uploads],
    components: {
        FormInput,
        ImageUploadInput,
        DefaultButton,
        OpenIcon,
        Drag,
        DropList
    },
    methods: {
        image_is_local (image) {
            return !image.remote_id && !!image.url && (!!image.url.match(/^\/storage/) || !!image.url.match(new RegExp(window.location.host)))
        },
        image_is_featured (image) {
            return !!this.maxFeatures && !!image.is_featured
        },
        image_is_featurable (image) {
            if(!this.maxFeatures) return false
            return !!image.is_featured || (this.featured_images.length < this.maxFeatures)
        },
        image_sort (event) {
            event.apply(this.sorted_images)
            this.$emit("sort", this.sorted_images.map(image => image.id))
        },
        image_post_name (image = null) {
            const prefix = this.image_post_prefix
            if(!image) return prefix
            return `${prefix}_${image.id}`
        },
        image_from_post_name (name) {
            const image_id = parseInt(name.replace(/^(image|banner)_url_/, ""))
            return this.images.find(image => image.id === image_id)
        },
        clear_or_confirm_clear_file (image) {
            const name = this.image_post_name(image)
            if(this.type === "TYPE_BANNER") {
                if(this.holding_mode) {
                    if(this.held_images[name]) {
                        URL.revokeObjectURL(this.held_images[name].src)
                        this.$delete(this.held_images, name, null)
                    }
                    this.$delete(this.held, name, null)
                } else {
                    this.confirming_clear_file = name
                    this.clear_file()
                }
            } else if(this.features) {
                this.save(image, !image.is_featured, "is_featured")
            } else this.confirm_clear_file(name)
        },
        upload_accepts (name, filename = null) {
            if(this.type === "TYPE_BANNER") {
                if(filename) return !!filename.match(/\.(png|jpe?g|mp4|mov)$/i)
                return "image/jpeg,image/png,.jpg,.jpeg,.png,video/mp4,.mp4,video/quicktime,.mov"
            }
            if(filename) return !!filename.match(/\.(png|jpe?g)$/i)
            return "image/jpeg,image/png,.jpg,.jpeg,.png"
        },
        upload_data (name) {
            const image = this.image_from_post_name(name)
            const data = {
                entity: this.owner,
                id: this.ownerId
            }
            if(image) data.data = { attachment_id: image.uuid }
            return data
        },
        confirmed () {
            this.clear_file()
        },
        upload_held (name, file) {
            const id = new_id--
            const new_name = `${this.image_post_prefix}_${Math.abs(id)}`
            this.$set(this.held, new_name, this.held[name])
            this.$delete(this.held, name)
            const image = this.generate_held_image(new_name)
            this.$emit("held", image, new_name, id, file)
        },
        upload_attach_error (name, error) {
            this.$emit("error", this.image_from_post_name(name), error.message)
        },
        upload_detach_error (name, error) {
            this.$emit("error", this.image_from_post_name(name), error.message)
        },
        upload_detached (name, response) {
            this.done_confirming()
            const image = this.image_from_post_name(name)
            this.$store.dispatch("SWEEP", { entity: "attachment", id: image.id }).catch(error => {
                this.$error(error.message)
            })
        },
        save (image, value, field) {
            if(image) {
                if(image[field] !== value) image[field] = value
                if(image.id) return this.save_field_for_record_id("attachment", image.id, image[field], field)
            }
        }
    },
    computed: {
        holding_mode () {
            return !this.owner || !this.ownerId
        },
        sorted_images () {
            return orderBy(this.images, "sort_order", "asc")
        },
        featured_images () {
            return this.images.filter(image => !!image.is_featured)
        },
        sorted_posters () {
            const images = this.sorted_images
            if(this.mode === "view") return images.slice(0, 5)
            return images
        },
        image_post_prefix () {
            return (this.type === "TYPE_BANNER") ? "banner_url" : "image_url"
        },
        ratio () {
            if(this.type === "TYPE_BANNER") return "nibnut-ratio-banner"
            return "nibnut-ratio-gallery"
        }
    },
    props: {
        type: {
            type: String,
            default: "TYPE_IMAGE"
        },
        images: {
            type: Array,
            default () {
                return []
            }
        },
        mode: {
            type: String,
            validator: prop => !!prop && prop.match(/^(view|edit|sort)$/),
            default: "view"
        },
        owner: {
            type: String,
            default: ""
        },
        ownerId: {
            type: Number,
            default: 0
        },
        maxFeatures: {
            type: Number,
            default: 0
        },
        copiable: {
            default: false
        },
        features: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.gallery {
    .nibnut-document-uploader > .empty {
        padding: $unit-2;

        .empty-title.h6 {
            display: none;
        }
        .empty-action {
            margin-top: $unit-6;
        }
    }
    .gallery-item {
        position: relative;
        margin-bottom: $layout-spacing-lg;
        img {
            width: 100%;
            height: 100%;
        }
        & > .nibnut-aspect-ratio-box + .gallery-item-floating-buttons {
            position: absolute;
            top: 0;
            right: $layout-spacing;
            z-index: $zindex-3 + 1;

            & > button.btn {
                background-color: rgba(255, 255, 255, 0.8);
            }
        }
    }
    &.gallery-preview {
        position: relative;
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 2fr 1fr 1fr;
        grid-gap: $unit-1;
        height: 75vh;
        max-height: 500px;
        @media (max-width: $size-lg) {
            max-height: 350px;
        }

        & > .gallery-item {
            position: relative;
            // grid-row: 1/1;
            // grid-column: 1/1;
            margin-bottom: 0;
            overflow: hidden;

            &.gallery-hero {
                grid-row: 1/3;
            }

            & > img {
                position: absolute;
                right: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        & > button.btn.btn-link {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            grid-row: 2/2;
            grid-column: 3/3;
            background: rgba($dark-color,.65);
            border-radius: 0;
            color: #fff;
            line-height: 28px;
            padding: 0 8px;
            text-decoration: none;
            letter-spacing: 1px;
            text-transform: uppercase;
        }
    }
}
</style>
