<template>
    <div
        class="card card-overflow-visible pull-up graphed-statistics-card"
        @click.prevent="toggle_zoom(title, id)"
    >
        <div class="card-body">
            <div class="statistic-container d-flex align-items-center">
                <div class="flex-static">
                    <slot name="icon">
                        <open-icon
                            v-if="!!glyph"
                            :glyph="glyph"
                            size="4x"
                            :class="{ [`text-${color}`]: true }"
                        />
                    </slot>
                </div>
                <div class="flex-grow">
                    <h5>
                        {{ title }}
                    </h5>
                    <small class="text-gray">
                        <slot name="subtitle"><span class="d-invisible"></span></slot>
                    </small>
                </div>
                <div v-if="!graphOnly" class="flex-static text-right">
                    <h5>
                        {{ count | nibnut.number("0,0") }}<span v-if="asPercentage">&nbsp;%</span>
                    </h5>
                    <slot name="delta">
                        <small
                            :class="{ 'text-success': better_outcome, 'text-error': worst_outcome, 'text-gray': (!better_outcome && !worst_outcome), 'd-invisible': (lastCount < 0) }"
                        >
                            <span v-html="percentage_as_string"></span>
                            <open-icon
                                :glyph="arrow"
                                :class="{ 'd-invisible': !arrow }"
                            />
                        </small>
                    </slot>
                </div>
            </div>
        </div>
        <div
            v-if="!!graphData"
            :class="{ 'mt-2': graphOnly }"
            class="card-footer"
        >
            <Portal :name="id" to="destination-modal-destination" :disabled="!is_zoomed">
                <apexchart
                    :height="is_zoomed ? 200 : height"
                    type="area"
                    :options="chart_options"
                    :series="chart_data"
                />
            </Portal>
        </div>
    </div>
</template>

<script>
import OpenIcon from "@/nibnut/components/OpenIcon"
import VueApexCharts from "vue-apexcharts"
import { Portal } from "portal-vue"

const color_name_to_hex = (color_name) => {
    const matches = color_name.match(/^(.+?)(?:::(light))?$/)
    color_name = matches ? matches[1] : color_name
    const light = matches ? (matches[2] === "light") : false
    let hex = "#c50802"
    switch (color_name) {
    case "secondary":
        hex = "#f4f5fa"
        break
    case "tertiary":
        hex = "#00aeef"
        break
    case "dark":
        hex = "#464855"
        break
    case "gray":
        hex = "#464855"
        break
    case "light-gray":
        hex = "#c3c3c3"
        break
    case "light":
        hex = "#ffffff"
        break
    case "success":
        hex = "#32b643"
        break
    case "warning":
        hex = "#ffb700"
        break
    case "error":
        hex = "#e85600"
        break
    }
    return light ? `${hex}40` : hex
}

export default {
    name: "GraphedStatisticCard",
    components: {
        OpenIcon,
        Apexchart: VueApexCharts,
        Portal
    },
    mounted () {
        this.refresh()
    },
    watch: {
        graphData: "refresh"
    },
    methods: {
        toggle_zoom (title) {
            if(!this.graphData) return
            this.$emit("toggle-zoom", title, this.id)
        },
        refresh () {
            setTimeout(() => {
                window.dispatchEvent(new Event("resize"))
            }, 1000)
        }
    },
    computed: {
        percentage () {
            if(!this.count || !this.lastCount) return 0
            return ((this.count - this.lastCount) / this.lastCount) * 100
        },
        percentage_as_string () {
            const percentage = this.percentage
            if(!percentage) return `<small>${this.translate("unchanged")}</small>`
            if(Math.abs(percentage) < 1) return `${this.nibnut_filter("nibnut.number", [percentage, "0.0,00"])}&nbsp;%`
            return `${this.nibnut_filter("nibnut.number", [percentage, "0,0"])}&nbsp;%`
        },
        better_outcome () {
            if(this.inverted) return this.percentage < 0
            return this.percentage > 0
        },
        worst_outcome () {
            if(this.inverted) return this.percentage > 0
            return this.percentage < 0
        },
        arrow () {
            const percentage = this.percentage
            if(!percentage) return ""
            return (this.percentage > 0) ? "arrow-up" : "arrow-down"
        },
        chart_options () {
            let categories = Object.keys(this.graphData)
            let yaxis = {
                show: this.is_zoomed,
                labels: {
                    formatter: (value) => {
                        return this.nibnut_filter("nibnut.number", [value, "0,0"])
                    }
                }
            }
            let opacity = 1
            let stroke_width = 1
            if(Array.isArray(this.graphData)) {
                categories = Object.keys(this.graphData[0].data)
                yaxis = this.graphData.map((graph_data, index) => {
                    return {
                        show: this.is_zoomed && (!index || (index === (this.graphData.length - 1))),
                        opposite: this.is_zoomed && !!index,
                        min: (min) => {
                            if(this.is_zoomed && (index < (this.graphData.length - 1))) {
                                return Math.min(...Object.values(this.graphData[0].data), ...Object.values(this.graphData[1].data))
                            }
                            return min
                        },
                        max: (max) => {
                            if(this.is_zoomed && (index < (this.graphData.length - 1))) {
                                return Math.max(...Object.values(this.graphData[0].data), ...Object.values(this.graphData[1].data))
                            }
                            return max
                        },
                        title: {
                            text: this.is_zoomed ? graph_data.title : undefined
                        },
                        labels: {
                            formatter: (value) => {
                                return this.nibnut_filter("nibnut.number", [value, "0,0"])
                            }
                        }
                    }
                })
                opacity = (new Array(this.graphData.length - 1)).fill(1)
                opacity.push(0.25)
                stroke_width = (new Array(this.graphData.length - 1)).fill(1)
                stroke_width.push(0)
            }
            const colors = (Array.isArray(this.color) ? this.color : [this.color]).map(color => {
                return color_name_to_hex(color)
            })
            let legend_colors
            if(!this.legendColor) legend_colors = colors
            else {
                legend_colors = (Array.isArray(this.legendColor) ? this.legendColor : [this.legendColor]).map(color => {
                    return color_name_to_hex(color)
                })
            }

            return {
                chart: {
                    id: `statistics-${this.glyph}`,
                    height: this.is_zoomed ? 200 : this.height,
                    type: "area",
                    fontFamily: "Quicksand, sans-serif",
                    sparkline: {
                        enabled: !this.is_zoomed
                    },
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: false
                    }
                },
                legend: {
                    show: this.is_zoomed,
                    markers: {
                        fillColors: legend_colors
                    }
                },
                colors,
                fill: {
                    // type: ["gradient", "solid"],
                    type: ["solid", "solid", "solid"],
                    opacity
                    /*
                    gradient: {
                        shade: "dark",
                        shadeIntensity: 0.1,
                        opacityFrom: 0.4,
                        opacityTo: 0.75,
                        stops: [0, 90, 150]
                    }
                    */
                },
                grid: {
                    padding: {
                        top: this.is_zoomed ? 0 : 5,
                        left: this.is_zoomed ? 30 : -11,
                        right: this.is_zoomed ? 30 : -3
                    },
                    xaxis: {
                        lines: {
                            show: false
                        }
                    }
                },
                dataLabels: {
                    enabled: this.is_zoomed,
                    enabledOnSeries: [0],
                    style: {
                        fontSize: this.is_zoomed ? "14px" : "10px"
                    },
                    background: {
                        padding: this.is_zoomed ? 4 : 2
                    },
                    formatter: (value) => {
                        if(value > 0) return value
                    }
                },
                stroke: {
                    width: stroke_width
                },
                xaxis: {
                    show: this.is_zoomed,
                    // type: "dateTime",
                    tickAmount: 12,
                    labels: {
                        formatter: (value) => {
                            return this.nibnut_filter("nibnut.date", [value, "MMM DD"])
                        }
                    },
                    categories
                },
                yaxis,
                plotOptions: {
                    bar: {
                        columnWidth: "60%"
                    }
                },
                tooltip: {
                    custom: Array.isArray(this.graphData) ? ({ series, seriesIndex, dataPointIndex, w }) => {
                        // console.log({ series, seriesIndex, dataPointIndex, w })
                        const body = this.graphData.map((graph_data, index) => {
                            return `<div><span class="dot" style="background-color: ${colors[index]};"></span>${graph_data.title}: ${this.nibnut_filter("nibnut.number", [series[index][dataPointIndex], "0,0"])}</div>`
                        })
                        let footer = this.tooltipFooterFormatter ? this.tooltipFooterFormatter({ series, seriesIndex, dataPointIndex, w }) : ""
                        if(footer) footer = `<div class="card-footer">${footer}</div>`
                        return `
                            <div class="card card-tooltip">
                                <div class="card-header">
                                    <div class="card-title text-bold">${w.globals.categoryLabels[dataPointIndex]}</div>
                                </div>
                                <div class="card-body">
                                    ${body.join("\n")}
                                </div>
                                ${footer}
                            </div>
                        `
                    } : undefined
                }
            }
        },
        chart_data () {
            if(Array.isArray(this.graphData)) {
                return this.graphData.map(graph_data => {
                    return {
                        name: graph_data.title || this.title,
                        type: graph_data.type || "area",
                        data: Object.values(graph_data.data)
                    }
                })
            }
            return [
                {
                    name: this.title,
                    data: Object.values(this.graphData)
                }
            ]
        },
        is_zoomed () {
            return this.zoomed === this.id
        }
    },
    props: {
        id: {
            type: String,
            required: true
        },
        title: {
            type: [String, Array],
            required: true
        },
        height: {
            type: Number,
            default: 90
        },
        count: {
            type: Number,
            required: true
        },
        lastCount: {
            type: Number,
            default: 0
        },
        inverted: {
            type: Boolean,
            default: false
        },
        graphData: { // { category1: data1, ... } OR [ { title: ..., type: ..., data: { category1: data1, ... } } ]
            type: [Object, Array],
            default: null
        },
        zoomed: {
            default: false
        },
        glyph: {
            type: String,
            default: ""
        },
        color: {
            type: [String, Array],
            validator: prop => !!prop && (Array.isArray(prop) || prop.match(/^(primary|secondary|tertiary|dark|gray|light|success|warning|error)$/)),
            default: "primary"
        },
        legendColor: {
            type: [String, Array],
            validator: prop => !!prop && (Array.isArray(prop) || prop.match(/^(primary|secondary|tertiary|dark|gray|light|success|warning|error)$/))
        },
        asPercentage: {
            type: Boolean,
            default: false
        },
        tooltipFooterFormatter: {
            default: null
        },
        graphOnly: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.graphed-statistics-card {
    & > .card-body {
        .statistic-container {
            & > div + div {
                margin-left: $unit-2;
            }
            h5 {
                margin-bottom: 0;
            }
            img {
                display: block;
                max-height: 64px;
            }
        }
    }
    & > .card-footer {
        &, &:last-child {
            padding: 0;
        }
    }
}
</style>
