<template>
    <div
        class="nibnut-aspect-ratio-box nibnut-ratio-gallery image-placeholder"
    >
        <div>
            <open-icon
                glyph="camera"
                :size="size"
            />
        </div>
    </div>
</template>

<script>
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "ImagePlaceholder",
    components: {
        OpenIcon
    },
    props: {
        size: {
            type: String,
            validator: prop => {
                return !prop || !!prop.match(/^(xs|1x|lg|2x|3x|4x|5x|6x|7x|8x|9x|10x)$/i)
            },
            default: "2x"
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.image-placeholder {
    background-color: $bg-color;
    color: $gray-color-light;

    i.las {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
</style>
