<template>
    <div
        :class="{ expanded: is_expanded }"
        class="card card-expandable"
    >
        <div class="card-header">
            <div class="card-title h5 d-flex" @click.prevent="toggle">
                <div class="flex-grow">
                    <slot name="title" :expanded="is_expanded"></slot>
                </div>
                <div v-if="expandable" class="flex-static">
                    <slot name="controls" :expanded="is_expanded"></slot>
                    <default-button
                        flavor="link"
                        size="sm"
                        @click.prevent.stop="toggle"
                    >
                        <open-icon glyph="angle-down" />
                    </default-button>
                </div>
            </div>
        </div>
        <div
            class="card-body"
        >
            <slot name="static-expandable-content"></slot>
            <div class="expandable-content">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "Expandable",
    components: {
        DefaultButton,
        OpenIcon
    },
    methods: {
        toggle () {
            if(this.expandable) this.expanded = !this.expanded
        }
    },
    computed: {
        is_expanded () {
            return !this.expandable || this.expanded
        }
    },
    props: {
        expandable: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            expanded: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.card-expandable {
    .card-header {
        .btn {
            flex: 0 0 auto;

            i.las {
                transition: transform .3s ease;
            }
        }
    }
    .card-body {
        display: flex;
        flex-direction: column;

        & > .expandable-content {
            flex: 0 1 0px;
            height: auto;
            overflow: hidden;
            transition: flex .3s ease;
        }
    }
    &.expanded {
        .card-header {
            .btn {
                i.las {
                    transform: rotate(180deg);
                }
            }
        }
        .card-body {
            & > .expandable-content {
                flex: 1 0 auto;
                overflow: visible;
            }
        }
    }
}
</style>
