<template>
    <div class="columns">
        <div class="column col-4 col-sm-12">
            <form-dropdown
                id="ga_account"
                name="ga_account"
                v-model="record.ga_account"
                :options="accounts"
                :required="false"
                @input="save('accounts')"
            >
                <template v-slot:label>{{ translate("Account") }}</template>
            </form-dropdown>
        </div>
        <div v-if="record.ga_account" class="column col-4 col-sm-12">
            <form-dropdown
                id="ga_property"
                name="ga_property"
                v-model="record.ga_property"
                :options="properties"
                :required="false"
                @input="save('properties')"
            >
                <template v-slot:label>{{ translate("Property") }}</template>
            </form-dropdown>
        </div>
        <div v-if="record.ga_property" class="column col-4 col-sm-12">
            <form-dropdown
                id="ga_profile"
                name="ga_profile"
                v-model="record.ga_profile"
                :options="profiles"
                :required="false"
                @input="save('profiles')"
            >
                <template v-slot:label>{{ translate("Profile") }}</template>
            </form-dropdown>
        </div>
    </div>
</template>

<script type="text/javascript">
import { profile_utilities, handles_saving } from "@/nibnut/mixins"

import { FormDropdown } from "@/nibnut/components"

// https://developers.google.com/analytics/devguides/config/mgmt/v3/quickstart/service-php
export default {
    name: "GoogleAnalytics",
    mixins: [profile_utilities, handles_saving],
    components: {
        FormDropdown
    },
    mounted () {
        this.bootstrap()
    },
    watch: {
        profile_id: "bootstrap",
        "record.id": "bootstrap"
    },
    methods: {
        emit_data () {
            this.$emit(
                "input",
                {
                    ga_account: this.record.ga_account,
                    ga_property: this.record.ga_property,
                    ga_profile: this.record.ga_profile
                }
            )
        },
        bootstrap () {
            if(!this.profile_id || !this.record) return
            this.record_id = this.record.id
            this.load_list("accounts").then(() => {
                if(this.record.ga_account) {
                    this.load_list("properties", { account: this.record.ga_account }).then(() => {
                        if(this.record.ga_property) {
                            this.load_list("profiles", { account: this.record.ga_account, property: this.record.ga_property }).then(() => {
                                this.emit_data()
                            })
                        }
                    })
                }
            })
        },
        load_list (list_entity = "", data = null) {
            return this.$store.dispatch(
                "RECORDS_ACTION",
                {
                    entity: "website",
                    action: `google/${list_entity || "accounts"}`,
                    data,
                    passthru: true
                }
            ).then(data => {
                this[list_entity] = data
                if((list_entity === "accounts") && !this.record.ga_account) this.record.ga_account = data[0].id
                else if((list_entity === "properties") && !this.record.ga_property) this.record.ga_property = data[0].id
                else if((list_entity === "profiles") && !this.record.ga_profile) this.record.ga_profile = data[0].id
            }).catch(error => {
                this.$error(error.message)
            })
        },
        save (list_entity) {
            if((list_entity === "accounts") || (list_entity === "properties")) {
                this.profiles = []
                this.record.ga_profile = ""
            }
            if(list_entity === "accounts") {
                this.properties = []
                this.record.ga_property = ""
            }
            /*
            this.save_data_for_record_id(this.entity, this.record_id, {
                ga_account: this.record.ga_account,
                ga_property: this.record.ga_property,
                ga_profile: this.record.ga_profile
            })
            */
            this.emit_data()
            if(list_entity === "properties") this.load_list("profiles", { account: this.record.ga_account, property: this.record.ga_property })
            else if(list_entity === "accounts") this.load_list("properties", { account: this.record.ga_account })
        }
    },
    props: {
        record: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            entity: "website",
            accounts: [],
            properties: [],
            profiles: []
        }
    }
}
</script>
