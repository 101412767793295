<template>
    <form-group
        v-bind="form_group_props"
    >
        <template v-slot:read_only><slot name="read_only"></slot></template>
        <template v-slot:empty-value><slot name="empty-value"></slot></template>
        <template v-slot:label><slot name="label"></slot></template>
        <template v-slot:hint><slot name="hint"></slot></template>
        <multi-select
            v-if="editable"
            :id="identifier"
            :name="name"
            :value="value"
            :options="options"
            :label="label"
            :placeholder="placeholder"
            :display="display"
            :scroll-height="scrollHeight"
            :disabled="disabled"
            :min-selection="minSelection || (required ? 1 : 0)"
            :max-selection="maxSelection"
            :can-toggle-all="canToggleAll"
            :close-on-toggle="closeOnToggle"
            :compact-selection="compactSelection"
            @input="input"
        />
    </form-group>
</template>

<script>
import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"

import FormGroup from "@/nibnut/components/Inputs/FormGroup"
import MultiSelect from "./MultiSelect"

export default {
    name: "FormMultiSelect",
    mixins: [is_nibnut_component],
    components: {
        FormGroup,
        MultiSelect
    },
    methods: {
        input (value, option) {
            this.$emit("input", value, this.name, option)
        }
    },
    computed: {
        form_group_props () {
            return {
                id: this.id,
                name: this.name,
                value: this.value,
                required: this.required,
                editable: this.editable,
                error: this.error,
                waiting: this.saving
            }
        }
    },
    props: {
        ...MultiSelect.props,
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        placeholder: {
            type: String,
            default: ""
        },
        required: {
            type: Boolean,
            required: true
        },
        editable: { // read-only
            type: Boolean,
            default: true
        },
        saving: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ""
        }
    }
}
</script>
