<template>
    <default-button
        v-if="!!uuid && is_at_least_realtor"
        v-bind="button_props"
        :disable="impersonating"
        :waiting="impersonating"
        @click.prevent="impersonate"
    >
        <slot>{{ $root.translate("Preview Dashboard") }}</slot>
    </default-button>
</template>

<script>
import addl_profile_utilities from "@/custom/mixins/AddlProfileUtilities"

import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"

export default {
    name: "ImpersonateButton",
    mixins: [addl_profile_utilities],
    components: {
        DefaultButton
    },
    methods: {
        set_impersonating (impersonating) {
            this.impersonating = impersonating
            this.$emit("clicked", this.impersonating)
        },
        impersonate () {
            if(!this.uuid) return
            this.set_impersonating(true)
            this.$store.dispatch("IMPERSONATE", {
                id: this.uuid,
                data: { listing_uuid: this.listingUuid, redirect: window.location.href }
            }).then(response => {
                if(this.route) this.$router.push(this.route)
                else if(response && response.meta && response.meta.redirect) window.location = response.meta.redirect
                else window.location.reload()
            }).catch(error => {
                this.$error(error.message || error)
            }).then(() => {
                this.set_impersonating(false)
            })
        }
    },
    computed: {
        button_props () {
            const { uuid, listingUuid, route, disable, waiting, ...props } = this.$props
            return props
        }
    },
    props: {
        uuid: {
            type: String,
            required: true
        },
        listingUuid: {
            type: String,
            default: null
        },
        route: {
            type: [Object, null],
            default () {
                return { name: "home" }
            }
        },
        ...DefaultButton.props,
        color: {
            type: String,
            validator: prop => !prop || prop.match(/^(primary|secondary|regular|error|warning|success|clear|light|white)$/i),
            default: "primary"
        },
        block: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            impersonating: false
        }
    }
}
</script>
