<template>
    <form-group
        v-bind="form_group_props"
    >
        <template v-slot:read_only>
            <slot name="read_only">
                <span
                    v-for="index in 5"
                    :key="index"
                    :class="{ 'text-gray': (index > value), 'text-primary': (index <= value) }"
                    class="mx-1"
                >
                    <open-icon glyph="star" />
                </span>
            </slot>
        </template>
        <template v-slot:empty-value><slot name="empty-value"></slot></template>
        <template v-slot:label><slot name="label"></slot></template>
        <template v-slot:hint><slot name="hint"></slot></template>

        <div
            :class="{ 'btn-group-block': block }"
            class="btn-group"
        >
            <default-button
                v-for="index in 5"
                :key="index"
                flavor="link"
                :size="size"
                :disabled="disabled"
                @click.prevent="$emit('input', index, name)"
            >
                <open-icon
                    glyph="star"
                    :class="{ 'text-gray': (index > value), 'text-primary': (index <= value) }"
                />
            </default-button>
        </div>
    </form-group>
</template>

<script>
import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"

import FormGroup from "@/nibnut/components/Inputs/FormGroup"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "FormRatingInput",
    mixins: [is_nibnut_component],
    components: {
        FormGroup,
        DefaultButton,
        OpenIcon
    },
    methods: {
    },
    computed: {
        has_label () {
            return this.has_slot("label")
        },
        form_group_props () {
            return {
                id: this.id,
                name: this.name,
                value: this.value,
                required: this.required,
                editable: this.editable,
                error: this.error,
                waiting: this.saving
            }
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        value: {
            type: Number,
            validator: prop => (prop >= 0) && (prop <= 5),
            default: 1
        },
        size: {
            type: String,
            validator: prop => !prop || prop.match(/^(xs|sm|md|lg)$/i),
            default: "md"
        },
        block: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            required: true
        },
        saving: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ""
        }
    }
}
</script>
